@charset "utf-8";


/* Device
--------------------- */

/* .pc-only */
@include max-screen($breakpoint-sp) {
	.pc-only {
		display: none!important;
	}
}

/* .tb-only */
.tb-only {
	display: none!important;
	@include max-screen($breakpoint-tb) {
		display: block!important;
	}
}
@include max-screen($breakpoint-tb) {
	br.tb-only {
		display: inline!important;
	}
}

/* .sp-only */
.sp-only {
	display: none!important;
	@include max-screen($breakpoint-sp) {
		display: block!important;
	}
}
@include max-screen($breakpoint-sp) {
	br.sp-only {
		display: inline!important;
	}
}


/* nowrap
--------------------- */

/* .u-nowrap */
.u-nowrap {
	white-space: nowrap;
}


/* Basic
--------------------- */

/* .align-right */
.align-right {
	text-align: right;
}

/* .align-center */
.align-center {
	text-align: center;
}

/* .bold */
.bold {
	font-weight: 700;
}

/* .mb0 */
.mb0 {
	margin-bottom: 0!important;
}


/* Heading
--------------------- */

.mod-hd1 {
	color: #000;
	font-size: 1rem;
	font-weight: bold;
	line-height: 1.5;
}


/* Decorate
--------------------- */

.mod-dec1 {
	font-size: 2rem;
	font-weight: bold;
}


/* Button
--------------------- */

.mod-btn1 {
	background-color: #000;
	border: 1px solid #000;
	border-radius: 10px;
	color: #fff;
	display: inline-block;
	font-size: 1rem;
	font-weight: bold;
	text-decoration: none;
	line-height: 1;
	padding: 10px 0;
	text-align: center;
	width: 100%;
	&:hover {
		color: #fff;
		opacity: 0.5;
	}
}

.mod-btn-email {
	background: $contact-btn-bg-color;
	border: 1px solid $contact-btn-border-color;
	color: #FFF!important;
	display: inline-block;
	font-size: 1.6rem;
	font-weight: 700;
	text-align: center;
	width: 100%;
	span {
		background: url("../img/common/mod_btn_email_ico.svg") 0% 50% no-repeat;
		display: inline-block;
		padding: 32px 0 32px 40px;
	}
	&:hover {
		color: #FFF!important;
		opacity: 0.5;
	}
}


/* Table
--------------------- */

.mod-table {
	border-left: 1px solid #e9e9e9;
	border-top: 1px solid #e9e9e9;
	font-size: 1.6rem;
	margin: 40px 0;
	width: 100%;
	th {
		background-color: #f9f9f9;
		border-bottom: 1px solid #e9e9e9;
		border-right: 1px solid #e9e9e9;
		font-size: 100%;
		line-height: 2;
		padding: 20px;
		vertical-align: top;
		width: 200px;
		> *:first-child {
			margin-top: 0!important;
		}
		> *:last-child {
			margin-bottom: 0!important;
		}
		@include max-screen($breakpoint-sp) {
			display: block;
			width: 100%;
		}
	}
	td {
		background-color: #fff;
		border-bottom: 1px solid #e9e9e9;
		border-right: 1px solid #e9e9e9;
		font-size: 100%;
		line-height: 2;
		padding: 20px;
		vertical-align: top;
		> *:first-child {
			margin-top: 0!important;
		}
		> *:last-child {
			margin-bottom: 0!important;
		}
		@include max-screen($breakpoint-sp) {
			display: block;
			width: 100%;
		}
	}
}


/* List
--------------------- */

.mod-list-disc {
	line-height: 1.5;
	list-style-type: disc;
	padding-left: 1.5em;
	li {
		margin-bottom: 0.5em;
	}
}


/* Section
--------------------- */

.mod-sec {
	margin-left: auto;
	margin-right: auto;
	max-width: 1100px;
	padding-left: 50px;
	padding-right: 50px;
	@include max-screen($breakpoint-tb) {
		padding-left: 4vw;
		padding-right: 4vw;
	}
}


/* Index
--------------------- */

.mod-index1 {
	> ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		@include max-screen($breakpoint-sp) {
			flex-direction: column;
		}
		> li {
			margin-bottom: 24px;
			width: calc(383% / 806) * 100;
			@include max-screen($breakpoint-sp) {
				width: 100%;
			}
			> p {
				a {
					align-items: center;
					background-color: #fff;
					box-shadow: 0 0 16px rgba($color: #000000, $alpha: .05);
					color: #0086CA;
					display: flex;
					font-size: 2rem;
					font-weight: 700;
					line-height: 1;
					padding: 26px 78px 26px 24px;
					position: relative;
					width: 100%;
					@include max-screen($breakpoint-sp) {
						font-size: 1.6rem;
						padding: calc(24 / $sp-source-w * 100vw) calc(70 / $sp-source-w * 100vw) calc(24 / $sp-source-w * 100vw) calc(16 / $sp-source-w * 100vw);
					}
					&::before {
						background: url(../img/common/ico_arrow_blk.svg) 0% 0% no-repeat;
						background-size: 100% 100%;
						content: '';
						display: block;
						height: 8px;
						position: absolute;
						right: 24px;
						transform: translate(0, -50%);
						top: 50%;
						width: 54px;
						z-index: 4;
						@media screen and (max-width: 1088px) {
							height: calc(8vw / 1088 * 100);
							right: calc(24vw / 1088 * 100);
							width: calc(54vw / 1088 * 100);
						}
						@include max-screen($breakpoint-sp) {
							height: calc(8vw / $sp-source-w * 100);
							right: calc(16vw / $sp-source-w * 100);
							width: calc(54vw / $sp-source-w * 100);
						}
					}
				}
			}
		}
		ul {
			padding: 16px 8px 0;
			li {
				border-top: 1px solid $bg-color;
				&:last-child {
					border-bottom: 1px solid $bg-color;
				}
				a {
					color: #0086CA;
					display: block;
					font-size: 1.4rem;
					padding: 8px 8px 8px 16px;
					position: relative;
					&::after {
						color: $main-font-color;
						content: '\f0da';
						font-family: $awesome;
						font-weight: 900;
						left: 0;
						position: absolute;
						top: 8px;
					}
				}
			}
		}
		span.pdf::after {
			background: url(../img/common/pdf.gif) 0% 0% no-repeat;
			background-size: 100% 100%;
			content: '';
			display: inline-block;
			height: 16px;
			margin-left: 4px;
			width: 14px;
		}
	}
}
.mod-index1__description {
	font-size: 1.4rem;
	line-height: calc(23.8em / 14);
	padding: 16px 8px 0;
}

.mod-index2 {
	margin-bottom: 16px;
	ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		@include max-screen($breakpoint-sp) {
			flex-direction: column;
		}
	}
	li {
		box-shadow: 0 8px 16px rgba($color: #000000, $alpha: .16);
		margin-bottom: calc(24 / 1088 * 100%);
		width: calc(524 / 1088 * 100%);
		position: relative;
		@include max-screen($breakpoint-sp) {
			margin-bottom: 16px;
			width: 100%;
		}
		figure {
			display: block;
			img {
				display: block;
				width: 100%;
			}
		}
		span {
			bottom: 0;
			display: block;
			height: 48px;
			left: 0;
			overflow: hidden;
			width: calc(376 / 524 * 100%);
			position: absolute;
			@include max-screen($breakpoint-sp) {
				height: calc(31 / $sp-source-w * 100vw);
				width: 100%;
			}
			&::after {
				background-color: rgba($color: #000000, $alpha: .4);
				content: '';
				display: block;
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 2;
			}
			&::before {
				background: url(../img/common/ico_arrow_wht.svg) 0% 0% no-repeat;
				background-size: 100% 100%;
				content: '';
				display: block;
				height: 8px;
				position: absolute;
				right: 8px;
				transform: translate(0, -50%);
				top: 50%;
				width: 54px;
				z-index: 4;
				@media screen and (max-width: 1088px) {
					height: calc(8vw / 1088 * 100);
					width: calc(54vw / 1088 * 100);
				}
				@include max-screen($breakpoint-sp) {
					height: calc(8vw / $sp-source-w * 100);
					width: calc(54vw / $sp-source-w * 100);
				}
			}
			img {
				bottom: 0;
				left: 0;
				position: absolute;
				width: calc(524 / 376 * 100%);
				filter: blur(6px);
				z-index: 1;
				@include max-screen($breakpoint-sp) {
					width: 100%;
				}
			}
		}
		a {
			color: #fff;
			text-decoration: none;
		}
	}
	&__title {
		bottom: 0;
		color: #fff;
		font-family: $mincho;
		font-size: 2rem;
		font-weight: 700;
		left: 0;
		line-height: 48px;
		padding-left: 8px;
		position: absolute;
		z-index: 3;
		@include max-screen($breakpoint-sp) {
			font-size: calc(18 / $sp-source-w * 100vw);
			line-height: calc(31 / $sp-source-w * 100vw);
			padding-left: calc(8 / $sp-source-w * 100vw);
		}
	}
}

.mod-index3 {
	@extend .mod-index2;
	ul {
		justify-content: flex-start;
	}
	li {
		margin-right: calc(40 / 1088 * 100%);
		width: calc(336 / 1088 * 100%);
		@include max-screen($breakpoint-sp) {
			margin-right: 0;
			width: 100%;
		}
		&:nth-child(3n) {
			margin-right: 0;
		}
		figure {
			height: 368px;
			@media screen and (max-width: 1088px) {
				height: calc(368 / 1088 * 100vw);
			}
			@include max-screen($breakpoint-sp) {
				height: calc(355 / $sp-source-w * 100vw);
			}
		}
		span {
			height: 32px;
			width: 100%;
			@include max-screen($breakpoint-sp) {
				height: calc(31 / $sp-source-w * 100vw);
			}
		}
	}
	&__title {
		@extend .mod-index2__title;
		line-height: 32px;
		@include max-screen($breakpoint-sp) {
			line-height: calc(31 / $sp-source-w * 100vw);
		}
	}
}


/* .strong
--------------------- */

.strong {
	font-weight: 700;
}


/* .cms-pallet
--------------------- */

span.cms-pallet-02 {
    color: #FF0000;
}
span.cms-pallet-06 {
	color: #808000;
}
span.cms-pallet-07 {
	color: #008080;
}
span.cms-pallet-10 {
    color: #CC6600;
}
span.cms-pallet-11 {
    color: #800080;
}


/* .v-bottom
--------------------- */

.v-bottom {
    vertical-align: bottom;
    font-size: 80%;
}


/* .download
--------------------- */

.download {
	list-style: none!important;
	margin: 0 0 8px!important;
	padding: 0!important;
	li {
		font-size: 1.4rem;
		a {
			display: inline-block;
			padding-left: 24px;
		}
		&.pdf {
			a {
				background: url("../img/common/pdf.gif") 0% 50% no-repeat;
			}
		}
		&.doc {
			a {
				background: url("../img/common/word.gif") 0% 50% no-repeat;
			}
		}
	}
}


/* .plugin-area  
--------------------- */

.plugin-area {
	border: 1px solid #CCC;
	margin: 24px 0;
	padding: 16px;
	.pdf-txt {
		font-size: 1.2rem!important;
		margin: 0!important;
		padding-right: 16px;
		@include max-screen($breakpoint-sp) {
			padding: 16px 0 0;
		}
	}
	.pdf-icon {
		margin: 0!important;
		img {
			height: auto;
			width: 159px;
		}
	}
}
.plugin-area-in {
	align-items: center;
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	@include max-screen($breakpoint-sp) {
		flex-direction: column;
	}
}


/* .table-area */
.table-area {
	margin-bottom: 24px;
	table {
		border-left: 1px solid $bg-color;
		border-top: 1px solid $bg-color;
		font-size: 1.4rem;
		width: 100%;
		th {
			background: #F2F2F2;
			border-bottom: 1px solid $bg-color;
			border-right: 1px solid $bg-color;
			font-weight: 700;
			padding: 10px 16px;
			@include max-screen($breakpoint-sp) {
				padding: 10px 8px;
			}
		}
		td {
			background-color: #fff;
			border-bottom: 1px solid $bg-color;
			border-right: 1px solid $bg-color;
			padding: 10px 16px;
			@include max-screen($breakpoint-sp) {
				padding: 10px 8px;
			}
		}
		&.border-none {
			border: 0;
			th {
				background: $bg-color2;
				border: 0;
				@include max-screen($breakpoint-sp) {
					display: block!important;
					width: 100%!important;
				}
			}
			td {
				background: #F9F9F9;
				border: 0;
				@include max-screen($breakpoint-sp) {
					display: block!important;
					width: 100%!important;
				}
			}
		}
	}
}


/* .table-area */
.table-area-02 {
	margin-bottom: 24px;
	table {
		border-left: 1px solid $bg-color;
		border-top: 1px solid $bg-color;
		font-size: 1.4rem;
		width: 100%;
		th {
			background: #F2F2F2;
			border-bottom: 1px solid $bg-color;
			border-right: 1px solid $bg-color;
			font-weight: 700;
			padding: 10px 16px;
			@include max-screen($breakpoint-sp) {
				padding: 10px 8px;
			}
		}
		td {
			background-color: #fff;
			border-bottom: 1px solid $bg-color;
			border-right: 1px solid $bg-color;
			padding: 10px 16px;
			@include max-screen($breakpoint-sp) {
				padding: 10px 8px;
			}
		}
		&.border-none {
			border: 0;
			th {
				background: $bg-color2;
				border: 0;
			}
			td {
				background: #F9F9F9;
				border: 0;
			}
		}
	}
}


/* .img
--------------------- */

.img {
	img {
		max-width: 100%;
	}
}


/* .special-pat01
--------------------- */

.special-pat01 {
	background: #fff;
	margin-bottom: 24px;
	.special {
		border: 1px solid $bg-color;
		padding: 0 24px 1px;
		@include max-screen($breakpoint-sp) {
			padding: 0 16px 1px;
		}
		&.clearfix::after {
			content: ".";
			display: block;
			height: 0;
			clear: both;
			visibility: hidden;
			line-height: 0;
			font-size: 0;
		}
	}
}
.special-title-area {
	background: #E5E5E5;
	color: $main-font-color;
	font-size: 1.4rem;
	font-weight: 700;
	margin: 0 -24px 24px;
	padding: 8px 24px;
	@include max-screen($breakpoint-sp) {
		margin: 0 -16px 16px;
		padding: 8px 16px;
	}
}
.special-tx {
	font-size: 1.4rem;
	font-weight: normal;
	line-height: 1.7;
	margin-bottom: 24px;
	@include max-screen($breakpoint-sp) {
		margin-bottom: 16px;
	}
}
.special-img {
	float: right;
	margin-bottom: 24px;
	margin-left: 24px;
	@include max-screen($breakpoint-sp) {
		margin-bottom: 16px;
		margin-left: 16px;
		img {
			width: 150px;
		}
	}
}

/* .valid-items
--------------------- */

.valid-items {
	margin-bottom: 24px;
}
.valid-items-title-area {
	background: #DC3545;
	color: #FFF;
	font-size: 1.6rem;
	font-weight: normal;
	padding: 8px 24px;
	@include max-screen($breakpoint-sp) {
		padding: 8px 16px;
	}
	.valid-items-title {
		font-weight: normal;
	}
}
.valid-items-tx {
	background: #FCEAEC;
	border: 1px solid #DC3545;
	color: #DC3545;
	font-size: 1.4rem;
	font-weight: normal;
	line-height: 1.7;
	padding: 24px;
	@include max-screen($breakpoint-sp) {
		padding: 16px;
	}
}


/* .cms-contents-list-ol
--------------------- */

.cms-contents-list-ol {
	font-size: 1.4rem;
	list-style-type: decimal;
	padding: 0 0 0 2em;
	li {
		margin: 0 0 8px;
		&:last-child {
			margin: 0;
		}
		> *:first-child {
			margin-top: 0!important;
		}
		> *:last-child {
			margin-bottom: 0!important;
		}
	}
}


/* .cms-contents-list-ul
--------------------- */

.cms-contents-list-ul {
	list-style-type: disc;
	margin: 8px 0 16px;
	padding: 0 0 0 2em;
	li {
		font-size: 1.4rem;
		margin: 0 0 8px;
		&:last-child {
			margin: 0;
		}
		> *:first-child {
			margin-top: 0!important;
		}
		> *:last-child {
			margin-bottom: 0!important;
		}
	}
}


/* .right-adjust
--------------------- */

.right-adjust {
	display: block;
	text-align: right;
}


/* .outline
--------------------- */

.outline {
	@extend %clearfix;
	padding-bottom: 24px;
	.img-area {
		float: right;
		padding-left: 24px;
		@include max-screen($breakpoint-sp) {
			float: none;
			margin-bottom: 24px;
			padding-left: 0;
			text-align: center;
		}
		img {
			max-width: 100%;
		}
	}
	.img-area--l {
		@extend .img-area;
		float: left;
		padding-left: 0;
		padding-right: 24px;
		@include max-screen($breakpoint-sp) {
			float: none;
			padding-right: 0;
		}
	}
}
.outline-tx {
    font-size: 1.4rem;
    line-height: 1.7;
}
.subtitle {
	color: $sub-color;
	font-size: 1.6rem;
	font-weight: 700;
	margin-bottom: 24px;
}


/* .center-justification
--------------------- */

.center-justification {
	display: block;
	text-align: center;
}


/* .cap-
--------------------- */

.cap-c {
	font-size: 1.4rem;
	padding-top: 8px;
	text-align: center;
}
.cap-l {
	font-size: 1.4rem;
	padding-top: 8px;
	text-align: left;
}


/* .img-pat02
--------------------- */

.img-pat02 {
	margin-bottom: 24px;
	text-align: center;
}


/* .tx-pat
--------------------- */

.tx-pat01 {
	margin-bottom: 40px;
}
.tx-pat02 {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	margin-bottom: 40px;
	@include max-screen($breakpoint-sp) {
		flex-direction: column;
	}
	.img {
		img {
			max-width: 100%;
		}
		@include max-screen($breakpoint-sp) {
			margin-bottom: 24px;
			text-align: center;
		}
	}
	.img-area {
		flex-shrink: 0;
	}
	.tx-area {
		padding-right: 24px;
		@include max-screen($breakpoint-sp) {
			padding-right: 0;
		}
	}
}
.tx-pat03 {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 40px;
	@include max-screen($breakpoint-sp) {
		flex-direction: column;
	}
	.img {
		img {
			max-width: 100%;
		}
		@include max-screen($breakpoint-sp) {
			margin-bottom: 24px;
			text-align: center;
		}
	}
	.img-area {
		flex-shrink: 0;
	}
	.tx-area {
		padding-left: 24px;
		@include max-screen($breakpoint-sp) {
			padding-left: 0;
		}
	}
}


/* .h2-area, .h3-area, .h4-area
--------------------- */

/* .h2-area */
.h2-area {
	border-bottom: 1px solid $bg-color;
	margin-bottom: 24px;
	padding: 0 0 16px;
}
.h2-deco {
	font-family: $mincho;
	font-size: 2rem;
	line-height: 1;
	padding: 0;
	position: relative;
	@include max-screen($breakpoint-sp) {
		font-size: 1.8rem;
	}
	> * {
		font-weight: 700;
	}
}

.h3-area,
.h4-area {
	background: #efefef;
	font-size: 1.6rem;
	line-height: 1.2;
	margin-bottom: 16px;
	padding: 10px 10px 10px 32px;
	position: relative;
	&::before {
		background: $bg-color;
		content: '';
		height: 12px;
		left: 12px;
		position: absolute;
		top: 16px;
		width: 12px;
	}
	&::after {
		background: $main-color;
		content: '';
		height: 12px;
		left: 8px;
		position: absolute;
		top: 12px;
		width: 12px;
	}
}


/* .link-area
--------------------- */

.link-area {
	.link-title {
		display: block;
		font-size: 1.6rem;
		margin-bottom: 16px;
		* {
			font-weight: 700;
		}
	}
	ul.str02 {
		ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			@include max-screen($breakpoint-sp) {
				flex-direction: column;
			}
			li {
				margin-bottom: 16px;
				width: 50%;
				@include max-screen($breakpoint-sp) {
					width: 100%;
				}
			}
			a {
				font-size: 1.4rem;
			}
		}
	}
}


/* .img-pat01
--------------------- */

.img-pat01 {
	display: flex;
	justify-content: space-between;
	margin-bottom: 24px;
	@include max-screen($breakpoint-sp) {
		flex-direction: column;
		margin-bottom: 0;
	}
	.img-area-l {
		text-align: left;
		width: 48%;
		@include max-screen($breakpoint-sp) {
			margin-bottom: 24px;
			text-align: center;
			width: 100%;
		}
		img {
			max-width: 100%;
		}
	}
	.img-area-r {
		text-align: right;
		width: 48%;
		@include max-screen($breakpoint-sp) {
			margin-bottom: 24px;
			text-align: center;
			width: 100%;
		}
		img {
			max-width: 100%;
		}
	}
}


/* .pagination
--------------------- */

.pagination {
	margin-top: 80px;
	@include max-screen($breakpoint-sp) {
		margin-top: 40px;
	}
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		position: relative;
		text-align: center;
		@include max-screen($breakpoint-sp) {
			text-align: center;
		}
	}
}
.pagination__item {
	display: inline-block;
	font-size: 1.6rem;
	height: 40px;
	margin: 0 10px 0 0;
	padding: 0;
	text-align: center;
	width: 40px;
	&:last-child {
		margin-right: 0;
	}
	&--first,
	&--last {
		display: none;
	}
	&--current {
		background: $main-color;
		border: 1px solid $main-color;
		color: #FFF;
		line-height: 38px;
	}
	@include max-screen($breakpoint-sp) {
		display: none;
		font-size: 4.27vw;
		height: 10.64vw;
		line-height: 10.64vw;
		margin: 0;
		width: 12%;
		&--current,
		&--prev,
		&--next,
		&--first,
		&--last {
			display: inline-block;
		}
		&--prev,
		&--next,
		&--first,
		&--last {
			position: absolute;
			top: 0;
		}
		&--prev {
			left: 14%;
		}
		&--next {
			right: 14%;
		}
		&--first {
			left: 0;
		}
		&--last {
			right: 0;
		}
		&--current {
			background: 0;
			border: 0;
			color: $main-font-color;
			line-height: 40px;
			width: 44%;
		}
	}
	a {
		background: #F5F5F5;
		border: 1px solid $main-font-color;
		color: $main-font-color;
		display: block;
		height: 100%;
		line-height: 38px;
		text-decoration: none;
		width: 100%;
		&:hover {
			background: $main-color;
			border: 1px solid $main-color;
			color: #FFF;
		}
	}
}
.pagination__sp-txt {
	display: none;
	@include max-screen($breakpoint-sp) {
		display: inline-block;
	}
}
@include max-screen($breakpoint-sp) {
	.pagination__pc-txt {
		display: none;
	}
}


.part-gallery {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 -12px;
	figure {
		padding: 0 12px 24px;
		img {
			max-width: 100%;
		}
	}
	figcaption {
		font-size: 1.4rem;
		padding-top: 8px;
		text-align: center;
	}
}