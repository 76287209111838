@charset "utf-8";

/* cols
--------------------- */

.cmn-cols {
	display: flex;
	justify-content: space-between;
	margin-bottom: 40px;
	@include max-screen($breakpoint-sp) {
		flex-direction: column;
	}
	.tx-area {
		padding-right: 24px;
		@include max-screen($breakpoint-sp) {
			padding-right: 0;
		}
	}
	.cmn-btn1 {
		flex-shrink: 0;
	}
}


/* Visual
--------------------- */

.cmn-visual {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	height: 320px;
	margin-top: 148px;
	width: 100%;
	position: relative;
	@media screen and (max-width: 1088px) {
		height: calc(320vw / 1088) * 100;
		margin-top: calc(148 / 1088 * 100vw);
	}
	@include max-screen($breakpoint-sp) {
		height: calc(216vw / $sp-source-w) * 100;
		margin-top: 0;
	}
	&::after {
		background: linear-gradient(to bottom, rgba($color: #000000, $alpha: 0) 0%, rgba($color: #05211C, $alpha: 1) 100%);
		bottom: 0;
		content: '';
		display: block;
		height: calc(200 / 320 * 100%);
		left: 0;
		opacity: .8;
		position: absolute;
		width: 100%;
		z-index: 1;
		@include max-screen($breakpoint-sp) {
			height: calc(142 / 216 * 100%);
		}
	}
	> div {
		justify-content: flex-end;
		display: flex;
		flex-direction: column;
		height: 100%;
		margin: 0 auto;
		max-width: 1104px;
		padding: 0 8px 16px;
		position: relative;
		width: 100%;
		z-index: 2;
		@include max-screen($breakpoint-sp) {
			padding: 0 calc(25 / $sp-source-w * 100vw) calc(16 / $sp-source-w * 100vw);
		}
	}
	&__txt {
		color: #FFF;
		font-family: $mincho;
		font-size: 4.8rem;
		font-weight: 700;
		line-height: 1;
		padding-top: 8px;
		@media screen and (max-width: 1088px) {
			font-size: calc(48vw / 1088) * 100;
		}
		@include max-screen($breakpoint-sp) {
			font-size: calc(30vw / $sp-source-w) * 100;
		}
	}
}


/* Topic path
--------------------- */

.cmn-topic-path {
	color: #fff;
	font-size: 1.4rem;
	line-height: 1;
	@media screen and (max-width: 1088px) {
		font-size: calc(14vw / 1088) * 100;
	}
	@include max-screen($breakpoint-sp) {
		font-size: calc(14vw / $sp-source-w) * 100;
	}
	li {
		display: inline;
		&::after {
			content: ' \f105';
			font-family: $awesome;
		}
		&:last-child::after {
			content: '';
		}
	}
	a {
		color: #fff;
	}
}


/* Contact
--------------------- */

.cmn-contact {
	text-align: center;
	> div {
		display: inline-block;
		text-align: left;
	}
	&__title {
		background-color: $sub-color;
		color: #fff;
		font-size: 1.8rem;
		font-weight: 700;
		line-height: 28px;
		margin-bottom: 8px;
		text-align: center;
		@include max-screen($breakpoint-sp) {
			font-size: calc(20vw / $sp-source-w) * 100;
			line-height: calc(32vw / $sp-source-w) * 100;
			margin-bottom: calc(8vw / $sp-source-w) * 100;
		}
	}
	&__tel {
		font-family: 'Roboto';
		font-size: 5.8rem;
		font-weight: bold;
		line-height: 1;
		margin-bottom: 10px;
		@include max-screen($breakpoint-sp) {
			font-size: calc(40vw / $sp-source-w) * 100;
			margin-bottom: calc(10vw / $sp-source-w) * 100;
		}
		a {
			background: url(../img/common/header_contact_ico_01.svg) 0% 50% no-repeat;
			background-size: 32px 32px;
			color: $sub-color;
			display: inline-block;
			padding-left: 40px;
			text-decoration: none;
			@include max-screen($breakpoint-sp) {
				background-size: calc(24vw / $sp-source-w) * 100 calc(24vw / $sp-source-w) * 100;
				padding-left: calc(36vw / $sp-source-w) * 100;
			}
		}
	}
	&__time {
		font-size: 1.8rem;
		line-height: 1;
		text-align: center;
		@include max-screen($breakpoint-sp) {
			font-size: calc(14vw / $sp-source-w) * 100;
		}
	}
}


/* Btn
--------------------- */

.cmn-btn1 {
	a {
		background: $contact-btn-bg-color;
		color: #FFF;
		display: block;
		font-size: 1.6rem;
		font-weight: bold;
		text-decoration: none;
		line-height: 1;
		padding: 50px 0;
		position: relative;
		text-align: center;
		text-decoration: none;
		width: 376px;
		@media screen and (max-width: 767px) {
			font-size: 1.4rem;
			width: 100%;
			padding: 25px 0;
		}
		&:hover {
			opacity: 0.5;
		}
		&::before {
			background: url("../img/common/mod_btn_email_ico.svg") 50% 50% no-repeat;
			background-size: 100% 100%;
			content: '';
			display: inline-block;
			height: 24px;
			margin-right: 8px;
			vertical-align: -0.4em;
			width: 32px;
		}
	}
}

.cmn-btn2 {
	a {
		background: $contact-btn-bg-color;
		color: #FFF;
		display: block;
		font-size: 1.6rem;
		font-weight: bold;
		text-decoration: none;
		line-height: 1;
		padding: 50px 0;
		text-align: center;
		text-decoration: none;
		width: 100%;
		@media screen and (max-width: 767px) {
			font-size: 1.4rem;
			padding: 25px 0;
		}
		&:hover {
			opacity: 0.5;
		}
		&::before {
			background: url("../img/common/btn_ico_02.svg") 50% 50% no-repeat;
			background-size: 100% 100%;
			content: '';
			display: inline-block;
			height: 24px;
			margin-right: 8px;
			vertical-align: -0.4em;
			width: 24px;
		}
	}
}

/* Print
--------------------- */

.cmn-print {
	margin-bottom: -40px;
	height: 40px;
	padding: 4px 0;
	position: relative;
	z-index: 2;
	@include max-screen($breakpoint-sp) {
		display: none;
	}
	ul {
		display: flex;
		height: 100%;
		justify-content: flex-end;
	}
	li {
		align-items: center;
		background-color: #A8AAB7;
		border: 1px solid #9799A4;
		color: #FFF;
		cursor: pointer;
		display: flex;
		font-size: 1.4rem;
		font-weight: 700;
		height: 100%;
		line-height: 1;
		padding-right: 8px;
		&:hover {
			opacity: 0.5;
		}
	}
	&__btn1 {
		background: url("../img/common/print_ico_01.svg") 8px 50% no-repeat;
		margin-right: 16px;
		padding-left: 32px;
	}
	&__btn2 {
		background: url("../img/common/print_ico_02.svg") 8px 50% no-repeat;
		padding-left: 30px;
	}
}

/* .h1-area */
.h1-area {
	border-bottom: 2px solid $main-font-color;
	margin-bottom: 40px;
	padding-bottom: 16px;
	position: relative;
	z-index: 1;
	@media screen and (max-width: 767px) {
		margin-bottom: 28px;
	}
}
.h1-area-in {
	color: $main-font-color;
	font-family: $mincho;
	font-size: 2.4rem;
	font-weight: 700;
	line-height: 1;
	padding: 8px 224px 8px 0;
	position: relative;
	z-index: 1;
	@include max-screen($breakpoint-sp) {
		font-size: 2rem;
		padding: 8px 0;
	}
	> * {
		font-weight: 700;
	}
}

/* .junction */
.junction {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	@include max-screen($breakpoint-sp) {
		flex-direction: column;
	}
}
.junc-left,
.junc-right {
	margin-bottom: 24px;
	width: calc(383% / 806) * 100;
	@include max-screen($breakpoint-sp) {
		margin-bottom: 16px;
		width: 100%;
	}
}
.junction-h2 {
	font-size: 2rem;
	font-weight: 700;
	@include max-screen($breakpoint-sp) {
		font-size: 1.6rem;
	}
	a {
		align-items: center;
		background: $local-nav-bg-color url("../img/common/junction_ico_01.svg") 8px 50% no-repeat;
		border: 1px solid $bg-color;
		display: flex;
		height: 72px;
		padding-left: 48px;
		@include max-screen($breakpoint-sp) {
			height: 64px;
		}
	}
}
.junction-outline-area {
	padding: 16px 8px 0;
}
.junction-outline-tx {
	font-size: 1.4rem;
	line-height: calc(23.8em / 14);
}
.junction-list {
	padding: 16px 8px 0;
	li {
		border-bottom: 1px solid $bg-color;
		&:first-child {
			border-top: 1px solid $bg-color;
		}
	}
	a {
		display: block;
		font-size: 1.4rem;
		line-height: 1.2;
		padding: 10px 0 10px 16px;
		position: relative;
		&::after {
			color: $main-font-color;
			content: ' \f0da';
			font-family: $awesome;
			font-weight: 900;
			left: 0;
			margin-top: -0.5em;
			position: absolute;
			top: 50%;
		}
	}
}
.junction-outline-area,
.junction-outline-img,
.junction-list {
	display: none;
}

/* .tx-area */
.tx-area {
	font-size: 1.4rem;
	line-height: calc(23.8em / 14);
	margin-bottom: 24px;
}


/* .anchor-area
--------------------- */

.anchor-area {
	background: #F2F2F2;
	margin: 24px 0;
	padding: 0 16px;
	.pageindex {
		display: none;
	}
	.str01,
	.str02 {
		list-style: none!important;
		padding: 0!important;
		a {
			background: url("../img/common/anchor_area_ico_01.svg") 0% 50% no-repeat;
			font-size: 1.4rem;
			line-height: calc(23.8em / 14);
			padding: 0 0 0 24px;
			display: inline-block;
		}
	}
	.str01 {
		li {
			padding: 10px 0;
		}
	}
	.str02 {
		li {
			ul {
				display: flex;
				flex-wrap: wrap;
				list-style: none!important;
				padding: 0!important;
				@include max-screen($breakpoint-sp) {
					flex-direction: column;
				}
				li {
					padding: 10px 0;
					width: 33.33%;
					@include max-screen($breakpoint-sp) {
						width: 100%;
					}
				}
			}
		}
	}
}


/* News Archive
--------------------- */

.news-archive {
	margin: 0 auto 40px;
	dl {
		border-bottom: 1px dotted rgba($color: #000000, $alpha: .2);
		display: flex;
		margin-bottom: 16px;
		padding-bottom: 12px;
		padding-top: 4px;
		@include max-screen($breakpoint-sp) {
			flex-direction: column;
			padding-bottom: 16px;
			padding-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
		&.em {
			dd {
				color: #FF0000!important;
			}
			a {
				color: #FF0000!important;
			}
		}
	}
	dt {
		font-size: 1.4rem;
		margin-right: 16px;
		@include max-screen($breakpoint-sp) {
			margin-bottom: 4px;
			margin-right: 0;
		}
	}
	dd {
		font-size: 1.4rem;
	}
	&__category {
		background-color: #000;
		color: #fff;
		display: inline-block;
		font-size: 1.2rem;
		height: 16px;
		line-height: 16px;
		margin-left: 26px;
		text-align: center;
		width: 48px;
	}
}


/* .go-top
--------------------- */

.go-top {
	display: none;
}


/* .go-top
--------------------- */

.adobe-reader {
	align-items: center;
	border: 1px solid #CCCCCC;
	display: flex;
	margin: 20px 0 40px;
	padding: 16px;
	@include max-screen($breakpoint-sp) {
		flex-direction: column-reverse;
	}
	p {
		font-size: 1.4rem;
		margin: 0!important;
		width: 100%;
		@include max-screen($breakpoint-sp) {
			font-size: 1.2rem;
		}
	}
	&__bnr {
		flex-shrink: 0;
		@include max-screen($breakpoint-sp) {
			margin-bottom: 16px;
		}
		img {
			width: 159px;
		}
	}
}


.sitemap__parent {
	background: #F1F4F8 url("../img/sitemap/sitemap_ico_02.svg") 14px 50% no-repeat;
	border: 1px solid #E5E5E5;
	display: block;
	font-size: 2rem;
	font-weight: 700;
	padding: 20px 20px 20px 48px;
	@include max-screen($breakpoint-sp) {
		font-size: 1.6rem;
	}
}