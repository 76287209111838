@charset "UTF-8";
/* reset */
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@500;700&display=swap);
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@import url(https://pro.fontawesome.com/releases/v5.14.0/css/all.css);
@import url(https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@1,400;1,500&display=swap);
html {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
}

body {
  height: 100%;
  margin: 0;
  overflow: visible;
  padding: 0;
  vertical-align: top;
  width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
  margin: 0;
}

p {
  margin: 0;
}

a img, img {
  border: 0;
  vertical-align: middle;
}

strong {
  font-style: normal;
  font-weight: bold;
}

small {
  font-size: 100%;
}

ol, ul {
  list-style-type: none;
}

dd, dl, dt, li, ol, ul {
  margin: 0;
  padding: 0;
}

hr {
  background-color: #CCCCCC;
  border: 0;
  color: #CCCCCC;
  height: 1px;
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 100%;
}

th {
  font-style: normal;
  font-weight: normal;
  margin: 0;
  padding: 0;
  text-align: left;
}

td {
  margin: 0;
  padding: 0;
}

blockquote, div, span {
  margin: 0;
  padding: 0;
}

pre {
  font-family: monospace;
  line-height: 1;
  margin: 0;
  padding: 0;
}

button {
  background: 0;
  border: 0;
  cursor: pointer;
  padding: 0;
  vertical-align: middle;
}

button, fieldset, form, input, label, legend, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

label {
  cursor: pointer;
}

textarea {
  resize: vertical;
}

* html textarea {
  margin-top: -2px;
}

*:first-child + html textarea {
  margin-top: -2px;
}

abbr, acronym {
  border: 0;
}

address, caption, cite, code, dfn, em, var {
  font-style: normal;
  font-weight: normal;
}

caption {
  text-align: left;
}

code, kbd, pre, samp, tt {
  font-family: monospace;
  line-height: 1;
}

* + html code, * + html kbd, * + html pre, * + html samp, * + html tt {
  font-size: 108%;
}

q:after, q:before {
  content: "";
}

article, aside, audio, canvas, projects, figcaption, figure, footer, header, hgroup, mark, menu, nav, section, summary, time, video {
  background: transparent;
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}

article, aside, projects, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

/* Vars
--------------------- */
/* ソースサイズ */
/* Mixin
--------------------- */
/* Media Queries */
/* Vendor Prefix */
/* Extend
--------------------- */
/* Clearfix */
.outline::after {
  clear: both;
  content: "";
  display: block;
}

/* Ellipsis */
/* PseudoTable */
/* Inner */
/* Device
--------------------- */
/* .pc-only */
@media screen and (max-width: 767px) {
  .pc-only {
    display: none !important;
  }
}
/* .tb-only */
.tb-only {
  display: none !important;
}
@media screen and (max-width: 1023px) {
  .tb-only {
    display: block !important;
  }
}

@media screen and (max-width: 1023px) {
  br.tb-only {
    display: inline !important;
  }
}
/* .sp-only */
.sp-only {
  display: none !important;
}
@media screen and (max-width: 767px) {
  .sp-only {
    display: block !important;
  }
}

@media screen and (max-width: 767px) {
  br.sp-only {
    display: inline !important;
  }
}
/* nowrap
--------------------- */
/* .u-nowrap */
.u-nowrap {
  white-space: nowrap;
}

/* Basic
--------------------- */
/* .align-right */
.align-right {
  text-align: right;
}

/* .align-center */
.align-center {
  text-align: center;
}

/* .bold */
.bold {
  font-weight: 700;
}

/* .mb0 */
.mb0 {
  margin-bottom: 0 !important;
}

/* Heading
--------------------- */
.mod-hd1 {
  color: #000;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
}

/* Decorate
--------------------- */
.mod-dec1 {
  font-size: 2rem;
  font-weight: bold;
}

/* Button
--------------------- */
.mod-btn1 {
  background-color: #000;
  border: 1px solid #000;
  border-radius: 10px;
  color: #fff;
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  line-height: 1;
  padding: 10px 0;
  text-align: center;
  width: 100%;
}
.mod-btn1:hover {
  color: #fff;
  opacity: 0.5;
}

.mod-btn-email {
  background: #0086CA;
  border: 1px solid #354052;
  color: #FFF !important;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  width: 100%;
}
.mod-btn-email span {
  background: url("../img/common/mod_btn_email_ico.svg") 0% 50% no-repeat;
  display: inline-block;
  padding: 32px 0 32px 40px;
}
.mod-btn-email:hover {
  color: #FFF !important;
  opacity: 0.5;
}

/* Table
--------------------- */
.mod-table {
  border-left: 1px solid #e9e9e9;
  border-top: 1px solid #e9e9e9;
  font-size: 1.6rem;
  margin: 40px 0;
  width: 100%;
}
.mod-table th {
  background-color: #f9f9f9;
  border-bottom: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
  font-size: 100%;
  line-height: 2;
  padding: 20px;
  vertical-align: top;
  width: 200px;
}
.mod-table th > *:first-child {
  margin-top: 0 !important;
}
.mod-table th > *:last-child {
  margin-bottom: 0 !important;
}
@media screen and (max-width: 767px) {
  .mod-table th {
    display: block;
    width: 100%;
  }
}
.mod-table td {
  background-color: #fff;
  border-bottom: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
  font-size: 100%;
  line-height: 2;
  padding: 20px;
  vertical-align: top;
}
.mod-table td > *:first-child {
  margin-top: 0 !important;
}
.mod-table td > *:last-child {
  margin-bottom: 0 !important;
}
@media screen and (max-width: 767px) {
  .mod-table td {
    display: block;
    width: 100%;
  }
}

/* List
--------------------- */
.mod-list-disc {
  line-height: 1.5;
  list-style-type: disc;
  padding-left: 1.5em;
}
.mod-list-disc li {
  margin-bottom: 0.5em;
}

/* Section
--------------------- */
.mod-sec {
  margin-left: auto;
  margin-right: auto;
  max-width: 1100px;
  padding-left: 50px;
  padding-right: 50px;
}
@media screen and (max-width: 1023px) {
  .mod-sec {
    padding-left: 4vw;
    padding-right: 4vw;
  }
}

/* Index
--------------------- */
.mod-index1 > ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .mod-index1 > ul {
    flex-direction: column;
  }
}
.mod-index1 > ul > li {
  margin-bottom: 24px;
  width: 47.5186104218%;
}
@media screen and (max-width: 767px) {
  .mod-index1 > ul > li {
    width: 100%;
  }
}
.mod-index1 > ul > li > p a {
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
  color: #0086CA;
  display: flex;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
  padding: 26px 78px 26px 24px;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .mod-index1 > ul > li > p a {
    font-size: 1.6rem;
    padding: 6.4vw 18.6666666667vw 6.4vw 4.2666666667vw;
  }
}
.mod-index1 > ul > li > p a::before {
  background: url(../img/common/ico_arrow_blk.svg) 0% 0% no-repeat;
  background-size: 100% 100%;
  content: "";
  display: block;
  height: 8px;
  position: absolute;
  right: 24px;
  transform: translate(0, -50%);
  top: 50%;
  width: 54px;
  z-index: 4;
}
@media screen and (max-width: 1088px) {
  .mod-index1 > ul > li > p a::before {
    height: 0.7352941176vw;
    right: 2.2058823529vw;
    width: 4.9632352941vw;
  }
}
@media screen and (max-width: 767px) {
  .mod-index1 > ul > li > p a::before {
    height: 2.1333333333vw;
    right: 4.2666666667vw;
    width: 14.4vw;
  }
}
.mod-index1 > ul ul {
  padding: 16px 8px 0;
}
.mod-index1 > ul ul li {
  border-top: 1px solid #E5E5E5;
}
.mod-index1 > ul ul li:last-child {
  border-bottom: 1px solid #E5E5E5;
}
.mod-index1 > ul ul li a {
  color: #0086CA;
  display: block;
  font-size: 1.4rem;
  padding: 8px 8px 8px 16px;
  position: relative;
}
.mod-index1 > ul ul li a::after {
  color: #354052;
  content: "\f0da";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  left: 0;
  position: absolute;
  top: 8px;
}
.mod-index1 > ul span.pdf::after {
  background: url(../img/common/pdf.gif) 0% 0% no-repeat;
  background-size: 100% 100%;
  content: "";
  display: inline-block;
  height: 16px;
  margin-left: 4px;
  width: 14px;
}

.mod-index1__description {
  font-size: 1.4rem;
  line-height: 1.7em;
  padding: 16px 8px 0;
}

.mod-index2, .mod-index3 {
  margin-bottom: 16px;
}
.mod-index2 ul, .mod-index3 ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .mod-index2 ul, .mod-index3 ul {
    flex-direction: column;
  }
}
.mod-index2 li, .mod-index3 li {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.16);
  margin-bottom: 2.2058823529%;
  width: 48.1617647059%;
  position: relative;
}
@media screen and (max-width: 767px) {
  .mod-index2 li, .mod-index3 li {
    margin-bottom: 16px;
    width: 100%;
  }
}
.mod-index2 li figure, .mod-index3 li figure {
  display: block;
}
.mod-index2 li figure img, .mod-index3 li figure img {
  display: block;
  width: 100%;
}
.mod-index2 li span, .mod-index3 li span {
  bottom: 0;
  display: block;
  height: 48px;
  left: 0;
  overflow: hidden;
  width: 71.7557251908%;
  position: absolute;
}
@media screen and (max-width: 767px) {
  .mod-index2 li span, .mod-index3 li span {
    height: 8.2666666667vw;
    width: 100%;
  }
}
.mod-index2 li span::after, .mod-index3 li span::after {
  background-color: rgba(0, 0, 0, 0.4);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
.mod-index2 li span::before, .mod-index3 li span::before {
  background: url(../img/common/ico_arrow_wht.svg) 0% 0% no-repeat;
  background-size: 100% 100%;
  content: "";
  display: block;
  height: 8px;
  position: absolute;
  right: 8px;
  transform: translate(0, -50%);
  top: 50%;
  width: 54px;
  z-index: 4;
}
@media screen and (max-width: 1088px) {
  .mod-index2 li span::before, .mod-index3 li span::before {
    height: 0.7352941176vw;
    width: 4.9632352941vw;
  }
}
@media screen and (max-width: 767px) {
  .mod-index2 li span::before, .mod-index3 li span::before {
    height: 2.1333333333vw;
    width: 14.4vw;
  }
}
.mod-index2 li span img, .mod-index3 li span img {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 139.3617021277%;
  filter: blur(6px);
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .mod-index2 li span img, .mod-index3 li span img {
    width: 100%;
  }
}
.mod-index2 li a, .mod-index3 li a {
  color: #fff;
  text-decoration: none;
}
.mod-index2__title, .mod-index3__title {
  bottom: 0;
  color: #fff;
  font-family: "Noto Serif JP", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-size: 2rem;
  font-weight: 700;
  left: 0;
  line-height: 48px;
  padding-left: 8px;
  position: absolute;
  z-index: 3;
}
@media screen and (max-width: 767px) {
  .mod-index2__title, .mod-index3__title {
    font-size: 4.8vw;
    line-height: 8.2666666667vw;
    padding-left: 2.1333333333vw;
  }
}

.mod-index3 ul {
  justify-content: flex-start;
}
.mod-index3 li {
  margin-right: 3.6764705882%;
  width: 30.8823529412%;
}
@media screen and (max-width: 767px) {
  .mod-index3 li {
    margin-right: 0;
    width: 100%;
  }
}
.mod-index3 li:nth-child(3n) {
  margin-right: 0;
}
.mod-index3 li figure {
  height: 368px;
}
@media screen and (max-width: 1088px) {
  .mod-index3 li figure {
    height: 33.8235294118vw;
  }
}
@media screen and (max-width: 767px) {
  .mod-index3 li figure {
    height: 94.6666666667vw;
  }
}
.mod-index3 li span {
  height: 32px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .mod-index3 li span {
    height: 8.2666666667vw;
  }
}
.mod-index3__title {
  line-height: 32px;
}
@media screen and (max-width: 767px) {
  .mod-index3__title {
    line-height: 8.2666666667vw;
  }
}

/* .strong
--------------------- */
.strong {
  font-weight: 700;
}

/* .cms-pallet
--------------------- */
span.cms-pallet-02 {
  color: #FF0000;
}

span.cms-pallet-06 {
  color: #808000;
}

span.cms-pallet-07 {
  color: #008080;
}

span.cms-pallet-10 {
  color: #CC6600;
}

span.cms-pallet-11 {
  color: #800080;
}

/* .v-bottom
--------------------- */
.v-bottom {
  vertical-align: bottom;
  font-size: 80%;
}

/* .download
--------------------- */
.download {
  list-style: none !important;
  margin: 0 0 8px !important;
  padding: 0 !important;
}
.download li {
  font-size: 1.4rem;
}
.download li a {
  display: inline-block;
  padding-left: 24px;
}
.download li.pdf a {
  background: url("../img/common/pdf.gif") 0% 50% no-repeat;
}
.download li.doc a {
  background: url("../img/common/word.gif") 0% 50% no-repeat;
}

/* .plugin-area  
--------------------- */
.plugin-area {
  border: 1px solid #CCC;
  margin: 24px 0;
  padding: 16px;
}
.plugin-area .pdf-txt {
  font-size: 1.2rem !important;
  margin: 0 !important;
  padding-right: 16px;
}
@media screen and (max-width: 767px) {
  .plugin-area .pdf-txt {
    padding: 16px 0 0;
  }
}
.plugin-area .pdf-icon {
  margin: 0 !important;
}
.plugin-area .pdf-icon img {
  height: auto;
  width: 159px;
}

.plugin-area-in {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .plugin-area-in {
    flex-direction: column;
  }
}

/* .table-area */
.table-area {
  margin-bottom: 24px;
}
.table-area table {
  border-left: 1px solid #E5E5E5;
  border-top: 1px solid #E5E5E5;
  font-size: 1.4rem;
  width: 100%;
}
.table-area table th {
  background: #F2F2F2;
  border-bottom: 1px solid #E5E5E5;
  border-right: 1px solid #E5E5E5;
  font-weight: 700;
  padding: 10px 16px;
}
@media screen and (max-width: 767px) {
  .table-area table th {
    padding: 10px 8px;
  }
}
.table-area table td {
  background-color: #fff;
  border-bottom: 1px solid #E5E5E5;
  border-right: 1px solid #E5E5E5;
  padding: 10px 16px;
}
@media screen and (max-width: 767px) {
  .table-area table td {
    padding: 10px 8px;
  }
}
.table-area table.border-none {
  border: 0;
}
.table-area table.border-none th {
  background: #f3f5e5;
  border: 0;
}
@media screen and (max-width: 767px) {
  .table-area table.border-none th {
    display: block !important;
    width: 100% !important;
  }
}
.table-area table.border-none td {
  background: #F9F9F9;
  border: 0;
}
@media screen and (max-width: 767px) {
  .table-area table.border-none td {
    display: block !important;
    width: 100% !important;
  }
}

/* .table-area */
.table-area-02 {
  margin-bottom: 24px;
}
.table-area-02 table {
  border-left: 1px solid #E5E5E5;
  border-top: 1px solid #E5E5E5;
  font-size: 1.4rem;
  width: 100%;
}
.table-area-02 table th {
  background: #F2F2F2;
  border-bottom: 1px solid #E5E5E5;
  border-right: 1px solid #E5E5E5;
  font-weight: 700;
  padding: 10px 16px;
}
@media screen and (max-width: 767px) {
  .table-area-02 table th {
    padding: 10px 8px;
  }
}
.table-area-02 table td {
  background-color: #fff;
  border-bottom: 1px solid #E5E5E5;
  border-right: 1px solid #E5E5E5;
  padding: 10px 16px;
}
@media screen and (max-width: 767px) {
  .table-area-02 table td {
    padding: 10px 8px;
  }
}
.table-area-02 table.border-none {
  border: 0;
}
.table-area-02 table.border-none th {
  background: #f3f5e5;
  border: 0;
}
.table-area-02 table.border-none td {
  background: #F9F9F9;
  border: 0;
}

/* .img
--------------------- */
.img img {
  max-width: 100%;
}

/* .special-pat01
--------------------- */
.special-pat01 {
  background: #fff;
  margin-bottom: 24px;
}
.special-pat01 .special {
  border: 1px solid #E5E5E5;
  padding: 0 24px 1px;
}
@media screen and (max-width: 767px) {
  .special-pat01 .special {
    padding: 0 16px 1px;
  }
}
.special-pat01 .special.clearfix::after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
  line-height: 0;
  font-size: 0;
}

.special-title-area {
  background: #E5E5E5;
  color: #354052;
  font-size: 1.4rem;
  font-weight: 700;
  margin: 0 -24px 24px;
  padding: 8px 24px;
}
@media screen and (max-width: 767px) {
  .special-title-area {
    margin: 0 -16px 16px;
    padding: 8px 16px;
  }
}

.special-tx {
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.7;
  margin-bottom: 24px;
}
@media screen and (max-width: 767px) {
  .special-tx {
    margin-bottom: 16px;
  }
}

.special-img {
  float: right;
  margin-bottom: 24px;
  margin-left: 24px;
}
@media screen and (max-width: 767px) {
  .special-img {
    margin-bottom: 16px;
    margin-left: 16px;
  }
  .special-img img {
    width: 150px;
  }
}

/* .valid-items
--------------------- */
.valid-items {
  margin-bottom: 24px;
}

.valid-items-title-area {
  background: #DC3545;
  color: #FFF;
  font-size: 1.6rem;
  font-weight: normal;
  padding: 8px 24px;
}
@media screen and (max-width: 767px) {
  .valid-items-title-area {
    padding: 8px 16px;
  }
}
.valid-items-title-area .valid-items-title {
  font-weight: normal;
}

.valid-items-tx {
  background: #FCEAEC;
  border: 1px solid #DC3545;
  color: #DC3545;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.7;
  padding: 24px;
}
@media screen and (max-width: 767px) {
  .valid-items-tx {
    padding: 16px;
  }
}

/* .cms-contents-list-ol
--------------------- */
.cms-contents-list-ol {
  font-size: 1.4rem;
  list-style-type: decimal;
  padding: 0 0 0 2em;
}
.cms-contents-list-ol li {
  margin: 0 0 8px;
}
.cms-contents-list-ol li:last-child {
  margin: 0;
}
.cms-contents-list-ol li > *:first-child {
  margin-top: 0 !important;
}
.cms-contents-list-ol li > *:last-child {
  margin-bottom: 0 !important;
}

/* .cms-contents-list-ul
--------------------- */
.cms-contents-list-ul {
  list-style-type: disc;
  margin: 8px 0 16px;
  padding: 0 0 0 2em;
}
.cms-contents-list-ul li {
  font-size: 1.4rem;
  margin: 0 0 8px;
}
.cms-contents-list-ul li:last-child {
  margin: 0;
}
.cms-contents-list-ul li > *:first-child {
  margin-top: 0 !important;
}
.cms-contents-list-ul li > *:last-child {
  margin-bottom: 0 !important;
}

/* .right-adjust
--------------------- */
.right-adjust {
  display: block;
  text-align: right;
}

/* .outline
--------------------- */
.outline {
  padding-bottom: 24px;
}
.outline .img-area, .outline .img-area--l {
  float: right;
  padding-left: 24px;
}
@media screen and (max-width: 767px) {
  .outline .img-area, .outline .img-area--l {
    float: none;
    margin-bottom: 24px;
    padding-left: 0;
    text-align: center;
  }
}
.outline .img-area img, .outline .img-area--l img {
  max-width: 100%;
}
.outline .img-area--l {
  float: left;
  padding-left: 0;
  padding-right: 24px;
}
@media screen and (max-width: 767px) {
  .outline .img-area--l {
    float: none;
    padding-right: 0;
  }
}

.outline-tx {
  font-size: 1.4rem;
  line-height: 1.7;
}

.subtitle {
  color: #116B7A;
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 24px;
}

/* .center-justification
--------------------- */
.center-justification {
  display: block;
  text-align: center;
}

/* .cap-
--------------------- */
.cap-c {
  font-size: 1.4rem;
  padding-top: 8px;
  text-align: center;
}

.cap-l {
  font-size: 1.4rem;
  padding-top: 8px;
  text-align: left;
}

/* .img-pat02
--------------------- */
.img-pat02 {
  margin-bottom: 24px;
  text-align: center;
}

/* .tx-pat
--------------------- */
.tx-pat01 {
  margin-bottom: 40px;
}

.tx-pat02 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: 40px;
}
@media screen and (max-width: 767px) {
  .tx-pat02 {
    flex-direction: column;
  }
}
.tx-pat02 .img img {
  max-width: 100%;
}
@media screen and (max-width: 767px) {
  .tx-pat02 .img {
    margin-bottom: 24px;
    text-align: center;
  }
}
.tx-pat02 .img-area, .tx-pat02 .outline .img-area--l, .outline .tx-pat02 .img-area--l {
  flex-shrink: 0;
}
.tx-pat02 .tx-area {
  padding-right: 24px;
}
@media screen and (max-width: 767px) {
  .tx-pat02 .tx-area {
    padding-right: 0;
  }
}

.tx-pat03 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
}
@media screen and (max-width: 767px) {
  .tx-pat03 {
    flex-direction: column;
  }
}
.tx-pat03 .img img {
  max-width: 100%;
}
@media screen and (max-width: 767px) {
  .tx-pat03 .img {
    margin-bottom: 24px;
    text-align: center;
  }
}
.tx-pat03 .img-area, .tx-pat03 .outline .img-area--l, .outline .tx-pat03 .img-area--l {
  flex-shrink: 0;
}
.tx-pat03 .tx-area {
  padding-left: 24px;
}
@media screen and (max-width: 767px) {
  .tx-pat03 .tx-area {
    padding-left: 0;
  }
}

/* .h2-area, .h3-area, .h4-area
--------------------- */
/* .h2-area */
.h2-area {
  border-bottom: 1px solid #E5E5E5;
  margin-bottom: 24px;
  padding: 0 0 16px;
}

.h2-deco {
  font-family: "Noto Serif JP", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-size: 2rem;
  line-height: 1;
  padding: 0;
  position: relative;
}
@media screen and (max-width: 767px) {
  .h2-deco {
    font-size: 1.8rem;
  }
}
.h2-deco > * {
  font-weight: 700;
}

.h3-area,
.h4-area {
  background: #efefef;
  font-size: 1.6rem;
  line-height: 1.2;
  margin-bottom: 16px;
  padding: 10px 10px 10px 32px;
  position: relative;
}
.h3-area::before,
.h4-area::before {
  background: #E5E5E5;
  content: "";
  height: 12px;
  left: 12px;
  position: absolute;
  top: 16px;
  width: 12px;
}
.h3-area::after,
.h4-area::after {
  background: #0086CA;
  content: "";
  height: 12px;
  left: 8px;
  position: absolute;
  top: 12px;
  width: 12px;
}

/* .link-area
--------------------- */
.link-area .link-title {
  display: block;
  font-size: 1.6rem;
  margin-bottom: 16px;
}
.link-area .link-title * {
  font-weight: 700;
}
.link-area ul.str02 ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .link-area ul.str02 ul {
    flex-direction: column;
  }
}
.link-area ul.str02 ul li {
  margin-bottom: 16px;
  width: 50%;
}
@media screen and (max-width: 767px) {
  .link-area ul.str02 ul li {
    width: 100%;
  }
}
.link-area ul.str02 ul a {
  font-size: 1.4rem;
}

/* .img-pat01
--------------------- */
.img-pat01 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
@media screen and (max-width: 767px) {
  .img-pat01 {
    flex-direction: column;
    margin-bottom: 0;
  }
}
.img-pat01 .img-area-l {
  text-align: left;
  width: 48%;
}
@media screen and (max-width: 767px) {
  .img-pat01 .img-area-l {
    margin-bottom: 24px;
    text-align: center;
    width: 100%;
  }
}
.img-pat01 .img-area-l img {
  max-width: 100%;
}
.img-pat01 .img-area-r {
  text-align: right;
  width: 48%;
}
@media screen and (max-width: 767px) {
  .img-pat01 .img-area-r {
    margin-bottom: 24px;
    text-align: center;
    width: 100%;
  }
}
.img-pat01 .img-area-r img {
  max-width: 100%;
}

/* .pagination
--------------------- */
.pagination {
  margin-top: 80px;
}
@media screen and (max-width: 767px) {
  .pagination {
    margin-top: 40px;
  }
}
.pagination ul {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .pagination ul {
    text-align: center;
  }
}

.pagination__item {
  display: inline-block;
  font-size: 1.6rem;
  height: 40px;
  margin: 0 10px 0 0;
  padding: 0;
  text-align: center;
  width: 40px;
}
.pagination__item:last-child {
  margin-right: 0;
}
.pagination__item--first, .pagination__item--last {
  display: none;
}
.pagination__item--current {
  background: #0086CA;
  border: 1px solid #0086CA;
  color: #FFF;
  line-height: 38px;
}
@media screen and (max-width: 767px) {
  .pagination__item {
    display: none;
    font-size: 4.27vw;
    height: 10.64vw;
    line-height: 10.64vw;
    margin: 0;
    width: 12%;
  }
  .pagination__item--current, .pagination__item--prev, .pagination__item--next, .pagination__item--first, .pagination__item--last {
    display: inline-block;
  }
  .pagination__item--prev, .pagination__item--next, .pagination__item--first, .pagination__item--last {
    position: absolute;
    top: 0;
  }
  .pagination__item--prev {
    left: 14%;
  }
  .pagination__item--next {
    right: 14%;
  }
  .pagination__item--first {
    left: 0;
  }
  .pagination__item--last {
    right: 0;
  }
  .pagination__item--current {
    background: 0;
    border: 0;
    color: #354052;
    line-height: 40px;
    width: 44%;
  }
}
.pagination__item a {
  background: #F5F5F5;
  border: 1px solid #354052;
  color: #354052;
  display: block;
  height: 100%;
  line-height: 38px;
  text-decoration: none;
  width: 100%;
}
.pagination__item a:hover {
  background: #0086CA;
  border: 1px solid #0086CA;
  color: #FFF;
}

.pagination__sp-txt {
  display: none;
}
@media screen and (max-width: 767px) {
  .pagination__sp-txt {
    display: inline-block;
  }
}

@media screen and (max-width: 767px) {
  .pagination__pc-txt {
    display: none;
  }
}
.part-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -12px;
}
.part-gallery figure {
  padding: 0 12px 24px;
}
.part-gallery figure img {
  max-width: 100%;
}
.part-gallery figcaption {
  font-size: 1.4rem;
  padding-top: 8px;
  text-align: center;
}

/* General
--------------------- */
html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  background-color: #F9F9F9;
  color: #354052;
  font-family: "Noto Sans Japanese", "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", sans-serif;
  font-weight: 400;
  line-height: 1.5;
}
body * {
  box-sizing: border-box;
}

/* a */
a {
  color: #0086CA;
  text-decoration: underline;
}
a:hover {
  color: #0086CA;
  text-decoration: none;
}

/* hr */
hr {
  display: none;
}

/* Layout
--------------------- */
.layout {
  overflow: hidden;
  position: relative;
  width: 100%;
}

/* Header
--------------------- */
.header {
  background: #FFF;
  border-bottom: 1px solid #E5E5E5;
  height: 87px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}
@media only screen and (max-width: 1088px) {
  .header {
    height: 7.9963235294vw;
  }
}
@media screen and (max-width: 767px) {
  .header {
    border-bottom: 0;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
    height: 14.9333333333vw;
  }
}
@media screen and (max-width: 767px) {
  .header.opened {
    box-shadow: none;
  }
}
.header > div {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1360px;
  padding: 0 8px;
  position: relative;
  width: 100%;
}
@media only screen and (max-width: 1088px) {
  .header > div {
    padding: 0 0.7352941176vw;
  }
}
@media screen and (max-width: 767px) {
  .header > div {
    padding: 0 2.1333333333vw;
  }
}

/* .header__logo */
@media only screen and (max-width: 1088px) {
  .header__logo img {
    width: 29.2279411765vw;
  }
}
@media screen and (max-width: 767px) {
  .header__logo img {
    width: 54.6666666667vw;
  }
}

/* .header-col */
.header-col {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

/* .header__txt */
.header__txt {
  align-items: center;
  display: flex;
  margin-right: 16px;
}
@media only screen and (max-width: 1088px) {
  .header__txt {
    margin-right: 1.4705882353vw;
  }
}
@media screen and (max-width: 767px) {
  .header__txt {
    display: none;
  }
}
.header__txt__hd {
  font-size: 1.2rem;
  font-weight: 700;
  margin-right: 8px;
}
@media only screen and (max-width: 1088px) {
  .header__txt__hd {
    font-size: 1.1029411765vw;
    margin-right: 0.7352941176vw;
  }
}
.header__txt__items {
  align-items: center;
  display: flex;
}
.header__txt__item {
  background: #F5F5F5;
  border: 1px solid #E5E5E5;
  color: #354052;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 700;
  height: 36px;
  line-height: 34px;
  text-align: center;
  width: 40px;
}
@media only screen and (max-width: 1088px) {
  .header__txt__item {
    font-size: 1.2867647059vw;
    height: 3.3088235294vw;
    line-height: 3.125vw;
    width: 3.6764705882vw;
  }
}
.header__txt__item:hover, .header__txt__item.current {
  background: #0086CA;
  border: 1px solid #0086CA;
  color: #FFF;
}

/* .header__bg */
.header__bg {
  align-items: center;
  display: flex;
  margin-right: 16px;
}
@media only screen and (max-width: 1088px) {
  .header__bg {
    margin-right: 1.4705882353vw;
  }
}
@media screen and (max-width: 767px) {
  .header__bg {
    display: none;
  }
}
.header__bg__hd {
  font-size: 1.2rem;
  font-weight: 700;
  margin-right: 8px;
}
@media only screen and (max-width: 1088px) {
  .header__bg__hd {
    font-size: 1.1029411765vw;
    margin-right: 0.7352941176vw;
  }
}
.header__bg__items {
  align-items: center;
  display: flex;
}
.header__bg__item {
  border: 1px solid #E5E5E5;
  color: #354052;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 700;
  height: 36px;
  line-height: 34px;
  text-align: center;
  width: 40px;
}
@media only screen and (max-width: 1088px) {
  .header__bg__item {
    font-size: 1.2867647059vw;
    height: 3.3088235294vw;
    line-height: 3.125vw;
    width: 3.6764705882vw;
  }
}
.header__bg__item.wht {
  background: #FFF;
}
.header__bg__item.blu {
  background: #0000FF;
  color: #FFF;
}
.header__bg__item.yel {
  background: #FFFF00;
}
.header__bg__item.blk {
  background: #000000;
  color: #FFF;
}
.header__bg__item:hover {
  opacity: 0.5;
}

/* .header__search */
.header__search {
  height: 36px;
  width: 250px;
}
.header__search * {
  height: 100%;
}
@media screen and (max-width: 767px) {
  .header__search {
    background: #F1F4F8;
    border-top: 1px solid #D8DBDE;
    height: auto;
    left: 0;
    padding: 4.2666666667vw;
    position: fixed;
    top: 14.9333333333vw;
    width: 100%;
  }
}
.header__search #___gcse_0 * {
  box-sizing: content-box;
}
.header__search .gsc-search-button {
  box-sizing: border-box !important;
  height: 36px;
}
@media screen and (max-width: 767px) {
  .header__search .gsc-search-button {
    height: 49px;
  }
}
.header__search .gsc-input {
  padding-right: 0 !important;
}
@media screen and (max-width: 767px) {
  .header__search .gsc-input-box {
    box-sizing: border-box !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

/* .header__nav */
.header__nav {
  display: flex;
  font-size: 1.4rem;
  justify-content: flex-end;
  margin-top: 8px;
}
@media only screen and (max-width: 1088px) {
  .header__nav {
    font-size: 1.2867647059vw;
    margin-top: 0.7352941176vw;
  }
}
@media screen and (max-width: 767px) {
  .header__nav {
    display: none;
  }
}
.header__nav li::after {
  content: "｜";
}
.header__nav li:last-child::after {
  content: "";
}
.header__nav a {
  color: #354052;
  text-decoration: none;
}
.header__nav a:hover {
  text-decoration: underline;
}

/* .header__btn-menu */
.header__btn-menu {
  display: none;
}
@media screen and (max-width: 767px) {
  .header__btn-menu {
    background: #0086CA url("../img/common/header_btn_menu_ico_01.svg") 50% 50% no-repeat;
    background-size: 100% 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: block;
    height: 14.9333333333vw;
    position: absolute;
    right: 0;
    top: 0;
    width: 14.9333333333vw;
  }
  .header__btn-menu.opened {
    background-image: url("../img/common/header_btn_menu_ico_02.svg");
  }
}

/* .header__btn-search */
.header__btn-search {
  display: none;
}
@media screen and (max-width: 767px) {
  .header__btn-search {
    background: #0086CA url("../img/common/header_btn_search_ico_01.svg") 50% 50% no-repeat;
    background-size: 100% 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: block;
    height: 14.9333333333vw;
    position: absolute;
    right: 14.9333333333vw;
    top: 0;
    width: 14.9333333333vw;
  }
  .header__btn-search.opened {
    background-image: url("../img/common/header_btn_menu_ico_02.svg");
  }
}

/* Gnav
--------------------- */
.gnav {
  background: #FFF;
  border-bottom: 1px solid #E5E5E5;
  height: 61px;
  left: 0;
  position: fixed;
  top: 87px;
  width: 100%;
  z-index: 8;
}
@media only screen and (max-width: 1088px) {
  .gnav {
    height: 5.6066176471vw;
    top: 7.9963235294vw;
  }
}
@media screen and (max-width: 767px) {
  .gnav {
    display: none;
    height: 100%;
    left: 0;
    overflow: auto;
    padding-top: 14.9333333333vw;
    position: fixed;
    top: 0;
  }
}
.gnav a {
  color: #354052;
}
.gnav > ul {
  display: flex;
  height: 100%;
  justify-content: space-between;
  line-height: 1;
  margin: 0 auto;
  max-width: 1088px;
}
@media screen and (max-width: 767px) {
  .gnav > ul {
    border-top: 1px solid #E5E5E5;
    display: block;
    height: auto;
  }
}
.gnav > ul > li > span,
.gnav > ul > li > a {
  color: #354052;
  cursor: pointer;
  display: table-cell;
  font-size: 1.6rem;
  font-family: "Noto Serif JP", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: 500;
  height: 100%;
  padding-left: 16px;
  position: relative;
  text-decoration: none;
  vertical-align: middle;
}
@media only screen and (max-width: 1088px) {
  .gnav > ul > li > span,
  .gnav > ul > li > a {
    font-size: 1.4705882353vw;
  }
}
@media screen and (max-width: 767px) {
  .gnav > ul > li > span,
  .gnav > ul > li > a {
    font-family: "Noto Sans Japanese", "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", sans-serif;
    font-size: 3.7333333333vw;
    padding-left: 6.4vw;
  }
}
.gnav > ul > li > span::after,
.gnav > ul > li > a::after {
  content: "\f107";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.6rem;
  font-weight: 900;
  height: 16px;
  line-height: 16px;
  margin-top: -8px;
  position: absolute;
  right: 16px;
  top: 50%;
}
.gnav > ul > li > span:hover,
.gnav > ul > li > a:hover {
  color: #FFF;
}
@media screen and (max-width: 767px) {
  .gnav > ul > li > span:hover,
  .gnav > ul > li > a:hover {
    color: #354052;
  }
}
.gnav > ul > li > span::before,
.gnav > ul > li > a::before {
  background: #0086CA;
  content: "";
  height: 0;
  left: 0;
  position: absolute;
  top: 0;
  transition: 0.2s ease-in-out;
  width: 100%;
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .gnav > ul > li > span::before,
  .gnav > ul > li > a::before {
    background: #E5E5E5;
    height: 8.5333333333vw;
    margin-top: -4.2666666667vw;
    top: 50%;
    transition: none;
    width: 2.1333333333vw;
    z-index: 1;
  }
}
.gnav > ul > li > span:hover::before,
.gnav > ul > li > a:hover::before {
  height: 100%;
}
@media screen and (max-width: 767px) {
  .gnav > ul > li > span:hover::before,
  .gnav > ul > li > a:hover::before {
    height: 8.5333333333vw;
  }
}
@media screen and (max-width: 767px) {
  .gnav > ul > li > span {
    display: block;
    height: 14.9333333333vw;
    line-height: 14.9333333333vw;
  }
}
@media screen and (max-width: 767px) {
  .gnav > ul > li > span::after {
    background: url("../img/common/gnav_ico_02.svg") 50% 50% no-repeat;
    background-size: 100% auto;
    border-left: 1px solid #E5E5E5;
    content: "";
    display: block;
    height: 100%;
    margin-top: 0;
    right: 0;
    top: 0;
    width: 14.9333333333vw;
  }
}
@media screen and (max-width: 767px) {
  .gnav > ul > li > a::after {
    background: url("../img/common/gnav_ico_01.svg") 50% 50%;
    background-size: 4.2666666667vw auto;
    content: "";
    display: inline-block;
    height: 4.2666666667vw;
    margin-left: 2.1333333333vw;
    margin-top: 0;
    position: relative;
    right: 0;
    top: 0;
    vertical-align: middle;
    width: 4.2666666667vw;
  }
}
.gnav > ul > li {
  border-right: 1px solid #E5E5E5;
  display: table;
  height: 100%;
  position: relative;
  width: 25.9191176471%;
}
@media screen and (max-width: 767px) {
  .gnav > ul > li {
    border-bottom: 1px solid #E5E5E5;
    border-right: 0;
    height: 14.9333333333vw;
    width: 100%;
  }
  .gnav > ul > li.sp-only {
    display: table !important;
  }
}
.gnav > ul > li:first-child {
  border-left: 1px solid #E5E5E5;
  text-align: center;
  width: 22.2426470588%;
}
@media screen and (max-width: 767px) {
  .gnav > ul > li:first-child {
    border-left: 0;
    text-align: left;
    width: 100%;
  }
  .gnav > ul > li:first-child .sp-only {
    display: inline !important;
  }
}
.gnav > ul > li:first-child > span,
.gnav > ul > li:first-child > a {
  font-weight: 500;
  padding-left: 0;
}
@media screen and (max-width: 767px) {
  .gnav > ul > li:first-child > span,
  .gnav > ul > li:first-child > a {
    font-family: "Noto Sans Japanese", "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", sans-serif;
    font-size: 3.7333333333vw;
    padding-left: 6.4vw;
  }
}
.gnav > ul > li:first-child > span::after,
.gnav > ul > li:first-child > a::after {
  content: "";
}
.gnav > ul > li:first-child .icon-home {
  font-family: "Font Awesome 5 Pro";
  font-size: 2.4rem;
  font-weight: 900;
}
.gnav > ul > li.current::before {
  background: #0086CA;
  content: "";
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .gnav > ul > li.current::before {
    display: none;
  }
}
.gnav .header__contact {
  display: none;
}
@media screen and (max-width: 767px) {
  .gnav .header__contact {
    border-top: 1px solid #E5E5E5;
    display: block;
    margin-left: 0;
    padding: 4.2666666667vw 0;
  }
  .gnav .header__contact__hd {
    font-size: 6.1333333333vw;
    height: 8.5333333333vw;
    line-height: 8.5333333333vw;
    margin: 0 auto 1vw;
    width: 78.9333333333vw;
  }
  .gnav .header__contact__tel {
    font-size: 11.4666666667vw;
    margin-bottom: 2vw;
    text-align: center;
  }
  .gnav .header__contact__tel a {
    background: url("../img/common/header_contact_ico_02.svg") 0% 50% no-repeat;
    background-size: 7.4666666667vw 7.4666666667vw;
    color: #FFF;
    display: inline-block;
    padding-left: 10.6666666667vw;
  }
  .gnav .header__contact__time {
    color: #FFF;
    font-size: 4.8vw;
  }
}
.gnav .gnav__btn-return {
  display: none;
}
@media screen and (max-width: 767px) {
  .gnav .gnav__btn-return {
    background: #0086CA url("../img/common/header_btn_menu_ico_03.svg") 50% 50% no-repeat;
    background-size: 100% 100%;
    border: 1px solid #0086CA;
    cursor: pointer;
    height: 14.9333333333vw;
    position: fixed;
    right: 0;
    top: 0;
    width: 14.9333333333vw;
    z-index: 3;
  }
}

.gnav__lnav {
  background-color: rgba(255, 255, 255, 0.95);
  left: 50%;
  margin-left: -544px;
  padding: 24px 12px;
  position: fixed;
  top: 147px;
  width: 1088px;
  z-index: 2;
}
@media only screen and (max-width: 1088px) {
  .gnav__lnav {
    left: 0;
    margin-left: 0;
    top: 13.5110294118vw;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .gnav__lnav {
    background: #fff;
    left: 0;
    margin-left: 0;
    height: 100%;
    padding: 0;
    top: 0;
    width: 100%;
  }
}
.gnav__lnav__parent-hd {
  display: none;
}
@media screen and (max-width: 767px) {
  .gnav__lnav__parent-hd {
    box-shadow: 0 1px 0 0 #E5E5E5;
    display: block;
    font-family: "Noto Serif JP", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
    font-weight: 700;
    font-size: 5.3333333333vw;
    height: 14.9333333333vw;
    line-height: 14.9333333333vw;
    padding: 0 0 0 4.2666666667vw;
  }
}
.gnav__lnav__hd, .gnav__lnav__hd--last {
  border-left: 8px solid #E5E5E5;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1;
  margin-left: 12px;
  padding: 9px 0 9px 16px;
}
@media screen and (max-width: 767px) {
  .gnav__lnav__hd, .gnav__lnav__hd--last {
    border-top: 1px solid #E5E5E5;
    border-left: 0;
    font-size: 3.7333333333vw;
    height: 14.9333333333vw;
    line-height: 14.9333333333vw;
    margin-left: 0;
    padding: 0;
    position: relative;
  }
}
@media screen and (max-width: 767px) and (max-width: 767px) {
  .gnav__lnav__hd.has-child .ico, .has-child.gnav__lnav__hd--last .ico {
    background: url("../img/common/gnav_ico_04.svg") 50% 50% no-repeat;
    background-size: 100% auto;
    border-left: 1px solid #E5E5E5;
    display: block;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 14.9333333333vw;
  }
}
@media screen and (max-width: 767px) {
  .gnav__lnav__hd.opened .ico, .opened.gnav__lnav__hd--last .ico {
    background-image: url("../img/common/gnav_ico_03.svg");
  }
}
@media screen and (max-width: 767px) {
  .gnav__lnav__hd + .gnav__lnav__cols, .gnav__lnav__hd--last + .gnav__lnav__cols {
    position: relative;
  }
  .gnav__lnav__hd + .gnav__lnav__cols::after, .gnav__lnav__hd--last + .gnav__lnav__cols::after {
    background: #E5E5E5;
    bottom: -1px;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .gnav__lnav__hd--last {
    border-bottom: 1px solid #E5E5E5;
  }
}
.gnav__lnav__hd--last + .gnav__lnav__cols {
  border-top: 0 !important;
}
.gnav__lnav__hd a, .gnav__lnav__hd--last a {
  position: relative;
  text-decoration: none;
}
@media screen and (max-width: 767px) {
  .gnav__lnav__hd a, .gnav__lnav__hd--last a {
    display: block;
    padding-left: 6.4vw;
  }
}
.gnav__lnav__hd a::after, .gnav__lnav__hd--last a::after {
  background: url("../img/common/gnav_ico_01.svg") 50% 50%;
  background-size: 100% 100%;
  content: "";
  display: inline-block;
  height: 16px;
  margin-left: 8px;
  vertical-align: middle;
  width: 16px;
}
@media screen and (max-width: 767px) {
  .gnav__lnav__hd a::after, .gnav__lnav__hd--last a::after {
    background-size: 4.2666666667vw 4.2666666667vw;
    height: 4.2666666667vw;
    margin-left: 2.1333333333vw;
    width: 4.2666666667vw;
  }
}
@media screen and (max-width: 767px) {
  .gnav__lnav__hd a::before, .gnav__lnav__hd--last a::before {
    background: #E5E5E5;
    content: "";
    height: 8.5333333333vw;
    left: 0;
    margin-top: -4.2666666667vw;
    position: absolute;
    top: 50%;
    transition: none;
    width: 2.1333333333vw;
    z-index: 1;
  }
}
.gnav__lnav__hd a:hover, .gnav__lnav__hd--last a:hover {
  opacity: 0.5;
}
@media screen and (max-width: 767px) {
  .gnav__lnav__hd a:hover, .gnav__lnav__hd--last a:hover {
    opacity: 1;
  }
}
.gnav__lnav ul {
  display: flex;
  flex-wrap: wrap;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 12px;
}
@media screen and (max-width: 767px) {
  .gnav__lnav ul {
    justify-content: space-between;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
}
.gnav__lnav li {
  border-bottom: 1px solid #E5E5E5;
  border-top: 1px solid #E5E5E5;
  padding: 12.5px 0;
  margin-right: 2.3076923077%;
  width: 31.7307692308%;
}
.gnav__lnav li:nth-child(3n) {
  margin-right: 0;
}
@media screen and (max-width: 767px) {
  .gnav__lnav li {
    border-top: 0;
    display: table;
    margin-right: 0;
    padding: 0;
    width: 50%;
  }
  .gnav__lnav li:nth-child(2n+1) {
    border-right: 1px solid #E5E5E5;
  }
}
.gnav__lnav li a {
  display: inline-block;
  font-size: 1.4rem;
  line-height: 1.125;
  padding-left: 14px;
  position: relative;
  text-decoration: none;
}
@media screen and (max-width: 767px) {
  .gnav__lnav li a {
    display: table-cell;
    font-size: 3.7333333333vw;
    height: 14.9333333333vw;
    padding-left: 6.4vw;
    vertical-align: middle;
  }
}
.gnav__lnav li a::after {
  content: "\f0da";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  left: 0;
  line-height: 1;
  margin-top: -7px;
  position: absolute;
  top: 50%;
}
@media screen and (max-width: 767px) {
  .gnav__lnav li a::after {
    left: 2.6666666667vw;
  }
}
.gnav__lnav li a:hover {
  opacity: 0.5;
}
@media screen and (max-width: 767px) {
  .gnav__lnav li a:hover {
    opacity: 1;
  }
}
.gnav__lnav li a span {
  font-size: 1.1rem;
}
@media screen and (max-width: 767px) {
  .gnav__lnav li a span {
    font-size: 2.9333333333vw;
  }
}
.gnav__lnav__cols {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .gnav__lnav__cols .gnav__lnav__cols {
    border-top: 1px solid #E5E5E5;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 767px) {
  .gnav__lnav > .gnav__lnav__cols {
    height: calc(100% - 14.9333vw);
    overflow: auto;
  }
}
.gnav__lnav .gnav__lnav__col {
  width: 100%;
}
@media screen and (max-width: 767px) {
  .gnav__lnav .gnav__lnav__col {
    display: block;
    position: relative;
    width: 100% !important;
  }
}
.gnav__lnav .gnav__lnav__col .gnav__lnav__col {
  padding: 12px 12px 0 12px;
}
@media screen and (max-width: 767px) {
  .gnav__lnav .gnav__lnav__col .gnav__lnav__col {
    padding: 0;
    width: 50% !important;
  }
}

/* Contents
--------------------- */
.contents {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1104px;
  padding: 0 8px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .contents {
    padding: 6.4vw;
  }
}
.contents--single {
  display: block;
  margin: 32px auto 0;
  max-width: 1104px;
  padding: 0 8px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .contents--single {
    margin-top: 0;
    padding: 6.4vw;
  }
}

/* Main
--------------------- */
.main {
  padding: 40px 0;
  width: 74.0808823529%;
}
@media screen and (max-width: 767px) {
  .main {
    padding: 0;
    width: 100%;
  }
}

/* Sidebar
--------------------- */
.sidebar {
  padding: 40px 0;
  width: 22.2426470588%;
}
@media screen and (max-width: 767px) {
  .sidebar {
    display: none;
  }
}
.sidebar__nav {
  padding-bottom: 32px;
}
.sidebar__nav a {
  color: #354052;
  display: block;
  font-size: 1.4rem;
  line-height: 1;
  padding: 11px 0 11px 14px;
  position: relative;
  text-decoration: none;
}
@media screen and (max-width: 1088px) {
  .sidebar__nav a {
    font-size: 1.2867647059vw;
    padding: 1.0110294118vw 0 1.0110294118vw 1.2867647059vw;
  }
}
.sidebar__nav a::after {
  content: " \f0da";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  left: 0;
  margin-top: -0.5em;
  position: absolute;
  top: 50%;
}
.sidebar__nav a.current, .sidebar__nav a:hover {
  font-weight: 700;
}
.sidebar__nav a span {
  font-size: 1.1rem;
}
.sidebar__nav li li {
  padding-left: 14px;
}
@media screen and (max-width: 1088px) {
  .sidebar__nav li li {
    padding-left: 1.2867647059vw;
  }
}
.sidebar__nav li li a::after {
  content: " \f105";
  font-weight: normal;
}

/* Page-top
--------------------- */
.page-top {
  height: 64px;
  overflow: hidden;
  padding-right: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: -64px;
  width: 64px;
  z-index: 9;
}
@media screen and (max-width: 767px) {
  .page-top {
    height: 14.9333333333vw;
    padding-right: 0;
    top: -14.9333333333vw;
    width: 14.9333333333vw;
  }
}
.page-top.fixed {
  bottom: 0;
  position: fixed;
  top: auto;
}
@media screen and (max-width: 767px) {
  .page-top.fixed {
    bottom: 0;
  }
}
.page-top:hover {
  opacity: 0.5;
}
.page-top > div {
  background: #A8AAB7;
  cursor: pointer;
  height: 100%;
  line-height: 1;
  position: relative;
  right: 0;
  top: 0;
  width: 100%;
}
.page-top > div img {
  height: 100%;
  width: 100%;
}

/* Footer
--------------------- */
.footer {
  background-color: #fff;
  position: relative;
  width: 100%;
  z-index: 7;
}

/* Row1 */
.footer__row1 {
  background-color: #fff;
  padding: 60px 8px 42px;
}
@media screen and (max-width: 1088px) {
  .footer__row1 {
    padding: 5.5147058824vw 0.7352941176vw 3.8602941176vw;
  }
}
@media screen and (max-width: 767px) {
  .footer__row1 {
    display: none;
  }
}
.footer__row1 > div {
  display: flex;
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
}
.footer__row1__col {
  margin-right: 3.125%;
  width: 17.5%;
}
.footer__row1__col:last-child {
  margin-right: 0;
}
.footer__row1__col > ul > li {
  padding-bottom: 16px;
}
@media screen and (max-width: 1088px) {
  .footer__row1__col > ul > li {
    padding-bottom: 1.4705882353vw;
  }
}
.footer__row1__col > ul > li > a {
  box-shadow: 0 1px 0 #ccc, 0 2px 0 #fff;
  color: #354052;
  display: block;
  font-size: 1.8rem;
  line-height: 1.3222222222;
  padding-bottom: 16px;
  padding-left: 24px;
  position: relative;
  text-decoration: none;
}
@media screen and (max-width: 1088px) {
  .footer__row1__col > ul > li > a {
    font-size: 1.6544117647vw;
    padding-bottom: 1.4705882353vw;
    padding-left: 2.2058823529vw;
  }
}
.footer__row1__col > ul > li > a::after {
  align-items: center;
  background-color: #354052;
  border-radius: 8px;
  color: #fff;
  content: "\f0da";
  display: flex;
  font-size: 1.4rem;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  height: 16px;
  justify-content: center;
  left: 0;
  line-height: 1;
  position: absolute;
  top: 0.3em;
  width: 16px;
}
@media screen and (max-width: 1088px) {
  .footer__row1__col > ul > li > a::after {
    border-radius: 0.7352941176vw;
    font-size: 1.2867647059vw;
    height: 1.4705882353vw;
    width: 1.4705882353vw;
  }
}
@media screen and (max-width: 767px) {
  .footer__row1__col > ul > li > a::after {
    font-size: 2.9333333333vw;
  }
}
.footer__row1__col > ul ul {
  margin-top: 16px;
}
@media screen and (max-width: 1088px) {
  .footer__row1__col > ul ul {
    margin-top: 1.4705882353vw;
  }
}
.footer__row1__col > ul ul li {
  font-size: 1.4rem;
  line-height: 1.2;
  margin-bottom: 16px;
}
@media screen and (max-width: 1088px) {
  .footer__row1__col > ul ul li {
    font-size: 1.2867647059vw;
    margin-bottom: 1.4705882353vw;
  }
}
.footer__row1__col > ul ul li a {
  color: #354052;
  display: inline-block;
  padding-left: 24px;
  position: relative;
  text-decoration: none;
}
@media screen and (max-width: 1088px) {
  .footer__row1__col > ul ul li a {
    padding-left: 2.2058823529vw;
  }
}
.footer__row1__col > ul ul li a::after {
  content: "\f0da";
  font-size: 1.4rem;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  left: 7px;
  line-height: 1;
  position: absolute;
  top: 0.1em;
}
@media screen and (max-width: 1088px) {
  .footer__row1__col > ul ul li a::after {
    font-size: 1.2867647059vw;
    left: 0.6433823529vw;
  }
}
@media screen and (max-width: 767px) {
  .footer__row1__col > ul ul li a::after {
    font-size: 2.9333333333vw;
  }
}

/* Row2 */
.footer__row2 {
  background-color: #fff;
  padding: 40px 8px 32px;
}
@media screen and (max-width: 1088px) {
  .footer__row2 {
    padding: 5.5147058824vw 0.7352941176vw;
  }
}
@media screen and (max-width: 767px) {
  .footer__row2 {
    padding: 8.5333333333vw 6.6666666667vw 0;
  }
}
.footer__row2 > div {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .footer__row2 > div {
    align-items: flex-start;
    flex-direction: column;
  }
}
.footer__row2__col ul {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 1088px) {
  .footer__row2__col ul {
    flex-direction: column;
  }
}
@media screen and (max-width: 767px) {
  .footer__row2__col ul {
    flex-direction: row;
  }
}
.footer__row2__col li {
  margin-right: 40px;
}
@media screen and (max-width: 767px) {
  .footer__row2__col li {
    margin-bottom: 3.3333333333em;
    margin-right: 0;
    width: 50%;
  }
}
.footer__row2__col a {
  color: #354052;
  font-size: 1.2rem;
  line-height: 1;
  padding-left: 12px;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  .footer__row2__col a {
    font-size: 2.9333333333vw;
    padding-left: 3.2vw;
  }
}
.footer__row2__col a::after {
  content: "\f0da";
  font-size: 1.2rem;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  left: 0;
  line-height: 1;
  position: absolute;
  top: 0.35em;
}
@media screen and (max-width: 1088px) {
  .footer__row2__col a::after {
    font-size: 1.1029411765vw;
  }
}
@media screen and (max-width: 767px) {
  .footer__row2__col a::after {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) {
  .footer__logo {
    margin-bottom: 6.4vw;
  }
}
.footer__logo img {
  width: 230px;
}
@media screen and (max-width: 767px) {
  .footer__logo img {
    width: 48vw;
  }
}

.footer__address {
  font-size: 1.1rem;
  line-height: 2.1818181818;
  padding: 0 24px;
}
@media screen and (max-width: 767px) {
  .footer__address {
    font-size: 3.2vw;
    line-height: 1.7;
    margin-bottom: 10.1333333333vw;
    padding: 0;
  }
}
.footer__address dl {
  display: inline-block;
  position: relative;
}
@media screen and (max-width: 767px) {
  .footer__address dl {
    display: block;
  }
}
.footer__address dl::after {
  content: " / ";
}
@media screen and (max-width: 767px) {
  .footer__address dl::after {
    content: "";
  }
}
.footer__address dl dt,
.footer__address dl dd {
  display: inline;
}
.footer__address dl:last-child::after {
  content: "";
}

/* .footer__copyright */
.footer__copyright {
  background-color: #fff;
  font-size: 1.1rem;
  line-height: 1;
  padding: 0 8px 50px;
}
@media screen and (max-width: 1088px) {
  .footer__copyright {
    padding: 0 0.7352941176vw 4.5955882353vw;
  }
}
@media screen and (max-width: 767px) {
  .footer__copyright {
    font-size: 3.2vw;
    padding: 0 6.6666666667vw 10.6666666667vw;
  }
}
.footer__copyright > div {
  margin: 0 auto;
  max-width: 1280px;
  text-align: right;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .footer__copyright > div {
    text-align: center;
  }
}

/* cols
--------------------- */
.cmn-cols {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
@media screen and (max-width: 767px) {
  .cmn-cols {
    flex-direction: column;
  }
}
.cmn-cols .tx-area {
  padding-right: 24px;
}
@media screen and (max-width: 767px) {
  .cmn-cols .tx-area {
    padding-right: 0;
  }
}
.cmn-cols .cmn-btn1 {
  flex-shrink: 0;
}

/* Visual
--------------------- */
.cmn-visual {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 320px;
  margin-top: 148px;
  width: 100%;
  position: relative;
}
@media screen and (max-width: 1088px) {
  .cmn-visual {
    height: 29.4117647059vw;
    margin-top: 13.6029411765vw;
  }
}
@media screen and (max-width: 767px) {
  .cmn-visual {
    height: 57.6vw;
    margin-top: 0;
  }
}
.cmn-visual::after {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #05211c 100%);
  bottom: 0;
  content: "";
  display: block;
  height: 62.5%;
  left: 0;
  opacity: 0.8;
  position: absolute;
  width: 100%;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .cmn-visual::after {
    height: 65.7407407407%;
  }
}
.cmn-visual > div {
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  max-width: 1104px;
  padding: 0 8px 16px;
  position: relative;
  width: 100%;
  z-index: 2;
}
@media screen and (max-width: 767px) {
  .cmn-visual > div {
    padding: 0 6.6666666667vw 4.2666666667vw;
  }
}
.cmn-visual__txt {
  color: #FFF;
  font-family: "Noto Serif JP", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-size: 4.8rem;
  font-weight: 700;
  line-height: 1;
  padding-top: 8px;
}
@media screen and (max-width: 1088px) {
  .cmn-visual__txt {
    font-size: 4.4117647059vw;
  }
}
@media screen and (max-width: 767px) {
  .cmn-visual__txt {
    font-size: 8vw;
  }
}

/* Topic path
--------------------- */
.cmn-topic-path {
  color: #fff;
  font-size: 1.4rem;
  line-height: 1;
}
@media screen and (max-width: 1088px) {
  .cmn-topic-path {
    font-size: 1.2867647059vw;
  }
}
@media screen and (max-width: 767px) {
  .cmn-topic-path {
    font-size: 3.7333333333vw;
  }
}
.cmn-topic-path li {
  display: inline;
}
.cmn-topic-path li::after {
  content: " \f105";
  font-family: "Font Awesome 5 Pro";
}
.cmn-topic-path li:last-child::after {
  content: "";
}
.cmn-topic-path a {
  color: #fff;
}

/* Contact
--------------------- */
.cmn-contact {
  text-align: center;
}
.cmn-contact > div {
  display: inline-block;
  text-align: left;
}
.cmn-contact__title {
  background-color: #116B7A;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 8px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .cmn-contact__title {
    font-size: 5.3333333333vw;
    line-height: 8.5333333333vw;
    margin-bottom: 2.1333333333vw;
  }
}
.cmn-contact__tel {
  font-family: "Roboto";
  font-size: 5.8rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .cmn-contact__tel {
    font-size: 10.6666666667vw;
    margin-bottom: 2.6666666667vw;
  }
}
.cmn-contact__tel a {
  background: url(../img/common/header_contact_ico_01.svg) 0% 50% no-repeat;
  background-size: 32px 32px;
  color: #116B7A;
  display: inline-block;
  padding-left: 40px;
  text-decoration: none;
}
@media screen and (max-width: 767px) {
  .cmn-contact__tel a {
    background-size: 6.4vw 6.4vw;
    padding-left: 9.6vw;
  }
}
.cmn-contact__time {
  font-size: 1.8rem;
  line-height: 1;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .cmn-contact__time {
    font-size: 3.7333333333vw;
  }
}

/* Btn
--------------------- */
.cmn-btn1 a {
  background: #0086CA;
  color: #FFF;
  display: block;
  font-size: 1.6rem;
  font-weight: bold;
  text-decoration: none;
  line-height: 1;
  padding: 50px 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  width: 376px;
}
@media screen and (max-width: 767px) {
  .cmn-btn1 a {
    font-size: 1.4rem;
    width: 100%;
    padding: 25px 0;
  }
}
.cmn-btn1 a:hover {
  opacity: 0.5;
}
.cmn-btn1 a::before {
  background: url("../img/common/mod_btn_email_ico.svg") 50% 50% no-repeat;
  background-size: 100% 100%;
  content: "";
  display: inline-block;
  height: 24px;
  margin-right: 8px;
  vertical-align: -0.4em;
  width: 32px;
}

.cmn-btn2 a {
  background: #0086CA;
  color: #FFF;
  display: block;
  font-size: 1.6rem;
  font-weight: bold;
  text-decoration: none;
  line-height: 1;
  padding: 50px 0;
  text-align: center;
  text-decoration: none;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .cmn-btn2 a {
    font-size: 1.4rem;
    padding: 25px 0;
  }
}
.cmn-btn2 a:hover {
  opacity: 0.5;
}
.cmn-btn2 a::before {
  background: url("../img/common/btn_ico_02.svg") 50% 50% no-repeat;
  background-size: 100% 100%;
  content: "";
  display: inline-block;
  height: 24px;
  margin-right: 8px;
  vertical-align: -0.4em;
  width: 24px;
}

/* Print
--------------------- */
.cmn-print {
  margin-bottom: -40px;
  height: 40px;
  padding: 4px 0;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 767px) {
  .cmn-print {
    display: none;
  }
}
.cmn-print ul {
  display: flex;
  height: 100%;
  justify-content: flex-end;
}
.cmn-print li {
  align-items: center;
  background-color: #A8AAB7;
  border: 1px solid #9799A4;
  color: #FFF;
  cursor: pointer;
  display: flex;
  font-size: 1.4rem;
  font-weight: 700;
  height: 100%;
  line-height: 1;
  padding-right: 8px;
}
.cmn-print li:hover {
  opacity: 0.5;
}
.cmn-print__btn1 {
  background: url("../img/common/print_ico_01.svg") 8px 50% no-repeat;
  margin-right: 16px;
  padding-left: 32px;
}
.cmn-print__btn2 {
  background: url("../img/common/print_ico_02.svg") 8px 50% no-repeat;
  padding-left: 30px;
}

/* .h1-area */
.h1-area {
  border-bottom: 2px solid #354052;
  margin-bottom: 40px;
  padding-bottom: 16px;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .h1-area {
    margin-bottom: 28px;
  }
}

.h1-area-in {
  color: #354052;
  font-family: "Noto Serif JP", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1;
  padding: 8px 224px 8px 0;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .h1-area-in {
    font-size: 2rem;
    padding: 8px 0;
  }
}
.h1-area-in > * {
  font-weight: 700;
}

/* .junction */
.junction {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .junction {
    flex-direction: column;
  }
}

.junc-left,
.junc-right {
  margin-bottom: 24px;
  width: 47.5186104218%;
}
@media screen and (max-width: 767px) {
  .junc-left,
  .junc-right {
    margin-bottom: 16px;
    width: 100%;
  }
}

.junction-h2 {
  font-size: 2rem;
  font-weight: 700;
}
@media screen and (max-width: 767px) {
  .junction-h2 {
    font-size: 1.6rem;
  }
}
.junction-h2 a {
  align-items: center;
  background: #FCF7ED url("../img/common/junction_ico_01.svg") 8px 50% no-repeat;
  border: 1px solid #E5E5E5;
  display: flex;
  height: 72px;
  padding-left: 48px;
}
@media screen and (max-width: 767px) {
  .junction-h2 a {
    height: 64px;
  }
}

.junction-outline-area {
  padding: 16px 8px 0;
}

.junction-outline-tx {
  font-size: 1.4rem;
  line-height: 1.7em;
}

.junction-list {
  padding: 16px 8px 0;
}
.junction-list li {
  border-bottom: 1px solid #E5E5E5;
}
.junction-list li:first-child {
  border-top: 1px solid #E5E5E5;
}
.junction-list a {
  display: block;
  font-size: 1.4rem;
  line-height: 1.2;
  padding: 10px 0 10px 16px;
  position: relative;
}
.junction-list a::after {
  color: #354052;
  content: " \f0da";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  left: 0;
  margin-top: -0.5em;
  position: absolute;
  top: 50%;
}

.junction-outline-area,
.junction-outline-img,
.junction-list {
  display: none;
}

/* .tx-area */
.tx-area {
  font-size: 1.4rem;
  line-height: 1.7em;
  margin-bottom: 24px;
}

/* .anchor-area
--------------------- */
.anchor-area {
  background: #F2F2F2;
  margin: 24px 0;
  padding: 0 16px;
}
.anchor-area .pageindex {
  display: none;
}
.anchor-area .str01,
.anchor-area .str02 {
  list-style: none !important;
  padding: 0 !important;
}
.anchor-area .str01 a,
.anchor-area .str02 a {
  background: url("../img/common/anchor_area_ico_01.svg") 0% 50% no-repeat;
  font-size: 1.4rem;
  line-height: 1.7em;
  padding: 0 0 0 24px;
  display: inline-block;
}
.anchor-area .str01 li {
  padding: 10px 0;
}
.anchor-area .str02 li ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none !important;
  padding: 0 !important;
}
@media screen and (max-width: 767px) {
  .anchor-area .str02 li ul {
    flex-direction: column;
  }
}
.anchor-area .str02 li ul li {
  padding: 10px 0;
  width: 33.33%;
}
@media screen and (max-width: 767px) {
  .anchor-area .str02 li ul li {
    width: 100%;
  }
}

/* News Archive
--------------------- */
.news-archive {
  margin: 0 auto 40px;
}
.news-archive dl {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.2);
  display: flex;
  margin-bottom: 16px;
  padding-bottom: 12px;
  padding-top: 4px;
}
@media screen and (max-width: 767px) {
  .news-archive dl {
    flex-direction: column;
    padding-bottom: 16px;
    padding-top: 0;
  }
}
.news-archive dl:last-child {
  margin-bottom: 0;
}
.news-archive dl.em dd {
  color: #FF0000 !important;
}
.news-archive dl.em a {
  color: #FF0000 !important;
}
.news-archive dt {
  font-size: 1.4rem;
  margin-right: 16px;
}
@media screen and (max-width: 767px) {
  .news-archive dt {
    margin-bottom: 4px;
    margin-right: 0;
  }
}
.news-archive dd {
  font-size: 1.4rem;
}
.news-archive__category {
  background-color: #000;
  color: #fff;
  display: inline-block;
  font-size: 1.2rem;
  height: 16px;
  line-height: 16px;
  margin-left: 26px;
  text-align: center;
  width: 48px;
}

/* .go-top
--------------------- */
.go-top {
  display: none;
}

/* .go-top
--------------------- */
.adobe-reader {
  align-items: center;
  border: 1px solid #CCCCCC;
  display: flex;
  margin: 20px 0 40px;
  padding: 16px;
}
@media screen and (max-width: 767px) {
  .adobe-reader {
    flex-direction: column-reverse;
  }
}
.adobe-reader p {
  font-size: 1.4rem;
  margin: 0 !important;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .adobe-reader p {
    font-size: 1.2rem;
  }
}
.adobe-reader__bnr {
  flex-shrink: 0;
}
@media screen and (max-width: 767px) {
  .adobe-reader__bnr {
    margin-bottom: 16px;
  }
}
.adobe-reader__bnr img {
  width: 159px;
}

.sitemap__parent {
  background: #F1F4F8 url("../img/sitemap/sitemap_ico_02.svg") 14px 50% no-repeat;
  border: 1px solid #E5E5E5;
  display: block;
  font-size: 2rem;
  font-weight: 700;
  padding: 20px 20px 20px 48px;
}
@media screen and (max-width: 767px) {
  .sitemap__parent {
    font-size: 1.6rem;
  }
}

.bg-blu *:not(.cmn-visual *, .header__bg *, .mod-index2 *, .mod-index3 *, .cmn-btn1 *, .page-top *) {
  background-color: #0000FF !important;
  border-color: #fff !important;
  color: #FFF !important;
}

.bg-yel *:not(.cmn-visual *, .header__bg *, .mod-index2 *, .mod-index3 *, .cmn-btn1 *, .page-top *) {
  background-color: #FFFF00 !important;
  border-color: #000 !important;
  color: #000 !important;
}

.bg-blk *:not(.cmn-visual *, .header__bg *, .mod-index2 *, .mod-index3 *, .cmn-btn1 *, .page-top *) {
  background-color: #000 !important;
  border-color: #FFF !important;
  color: #FFF !important;
}