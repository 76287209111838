@charset "utf-8";
@import "reset";
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@500;700&display=swap);
//@import url(https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@import url(https://pro.fontawesome.com/releases/v5.14.0/css/all.css);
@import url(https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@1,400;1,500&display=swap);
@import "mixin";
@import "_module";
@import "global";
@import "common";

.bg-blu {
	*:not(.cmn-visual *, .header__bg *, .mod-index2 *, .cmn-btn1 *, .page-top *) {
		background-color: #0000FF!important;
		border-color: #fff!important;
		color: #FFF!important;
	}
}
.bg-yel {
	*:not(.cmn-visual *, .header__bg *, .mod-index2 *, .cmn-btn1 *, .page-top *) {
		background-color: #FFFF00!important;
		border-color: #000!important;
		color: #000!important;
	}
}
.bg-blk {
	*:not(.cmn-visual *, .header__bg *, .mod-index2 *, .cmn-btn1 *, .page-top *) {
		background-color: #000!important;
		border-color: #FFF!important;
		color: #FFF!important;
	}
}