@charset "utf-8";


/* General
--------------------- */

html {
	font-size: 62.5%;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust:     100%;
}

body {
	background-color: #F9F9F9;
	color: $main-font-color;
	font-family: $gothic;
	font-weight: 400;
	line-height: 1.5;
	* {
		box-sizing: border-box;
	}
}

/* a */
a {
	color: #0086CA;
	text-decoration: underline;
	&:hover {
		color: #0086CA;
		text-decoration: none;
	}
}

/* hr */
hr {
	display: none;
}


/* Layout
--------------------- */

.layout {
	overflow: hidden;
	position: relative;
	width: 100%;
}


/* Header
--------------------- */

.header {
	background: #FFF;
	border-bottom: 1px solid #E5E5E5;
	height: 87px;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9;
	@media only screen and (max-width: 1088px) {
		height: calc(87 / 1088 * 100vw);
	}
	@include max-screen($breakpoint-sp) {
		border-bottom: 0;
		box-shadow: 0 1px 0 0 rgba(0,0,0,0.1);
		height: calc(56 / $sp-source-w * 100vw);
	}
	&.opened {
		@include max-screen($breakpoint-sp) {
			box-shadow: none;
		}
	}
	> div {
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: space-between;
		margin: 0 auto;
		max-width: 1360px;
		padding: 0 8px;
		position: relative;
		width: 100%;
		@media only screen and (max-width: 1088px) {
			padding: 0 calc(8 / 1088 * 100vw);
		}
		@include max-screen($breakpoint-sp) {
			padding: 0 calc(8 / $sp-source-w * 100vw);
		}
	}
}

/* .header__logo */
.header__logo {
	img {
		@media only screen and (max-width: 1088px) {
			width: calc(318 / 1088 * 100vw);
		}
		@include max-screen($breakpoint-sp) {
			width: calc(205 / $sp-source-w * 100vw);
		}
	}
}

/* .header-col */
.header-col {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

/* .header__txt */
.header__txt {
	align-items: center;
	display: flex;
	margin-right: 16px;
	@media only screen and (max-width: 1088px) {
		margin-right: calc(16 / 1088 * 100vw);
	}
	@include max-screen($breakpoint-sp) {
		display: none;
	}
	&__hd {
		font-size: 1.2rem;
		font-weight: 700;
		margin-right: 8px;
		@media only screen and (max-width: 1088px) {
			font-size: calc(12 / 1088 * 100vw);
			margin-right: calc(8 / 1088 * 100vw);
		}
	}
	&__items {
		align-items: center;
		display: flex;
	}
	&__item {
		background: #F5F5F5;
		border: 1px solid #E5E5E5;
		color: $main-font-color;
		cursor: pointer;
		font-size: 1.4rem;
		font-weight: 700;
		height: 36px;
		line-height: 34px;
		text-align: center;
		width: 40px;
		@media only screen and (max-width: 1088px) {
			font-size: calc(14 / 1088 * 100vw);
			height: calc(36 / 1088 * 100vw);
			line-height: calc(34 / 1088 * 100vw);
			width: calc(40 / 1088 * 100vw);
		}
		&:hover,
		&.current {
			background: #0086CA;
			border: 1px solid #0086CA;
			color: #FFF;
		}
	}
}

/* .header__bg */
.header__bg {
	align-items: center;
	display: flex;
	margin-right: 16px;
	@media only screen and (max-width: 1088px) {
		margin-right: calc(16 / 1088 * 100vw);
	}
	@include max-screen($breakpoint-sp) {
		display: none;
	}
	&__hd {
		font-size: 1.2rem;
		font-weight: 700;
		margin-right: 8px;
		@media only screen and (max-width: 1088px) {
			font-size: calc(12 / 1088 * 100vw);
			margin-right: calc(8 / 1088 * 100vw);
		}
	}
	&__items {
		align-items: center;
		display: flex;
	}
	&__item {
		border: 1px solid #E5E5E5;
		color: $main-font-color;
		cursor: pointer;
		font-size: 1.4rem;
		font-weight: 700;
		height: 36px;
		line-height: 34px;
		text-align: center;
		width: 40px;
		@media only screen and (max-width: 1088px) {
			font-size: calc(14 / 1088 * 100vw);
			height: calc(36 / 1088 * 100vw);
			line-height: calc(34 / 1088 * 100vw);
			width: calc(40 / 1088 * 100vw);
		}
		&.wht {
			background: #FFF;
		}
		&.blu {
			background: #0000FF;
			color: #FFF;
		}
		&.yel {
			background: #FFFF00;
		}
		&.blk {
			background: #000000;
			color: #FFF;
		}
		&:hover {
			opacity: 0.5;
		}
	}
}

/* .header__search */
.header__search {
	height: 36px;
	width: 250px;
	* {
		height: 100%;
	}
	@include max-screen($breakpoint-sp) {
		background: #F1F4F8;
		border-top: 1px solid #D8DBDE;
		height: auto;
		left: 0;
		padding: calc(16 / $sp-source-w * 100vw);
		position: fixed;
		top: calc(56 / $sp-source-w * 100vw);
		width: 100%;
	}
	#___gcse_0 * {
		box-sizing: content-box;
	}
	.gsc-search-button {
		box-sizing: border-box!important;
		height: 36px;
		@include max-screen($breakpoint-sp) {
			height: 49px;
		}
	}
	.gsc-input {
		padding-right: 0!important;
	}
	@include max-screen($breakpoint-sp) {
		.gsc-input-box {
			box-sizing: border-box!important;
			padding-top: 0!important;
			padding-bottom: 0!important;
		}
	}
}

/* .header__nav */
.header__nav {
	display: flex;
	font-size: 1.4rem;
	justify-content: flex-end;
	margin-top: 8px;
	@media only screen and (max-width: 1088px) {
		font-size: calc(14 / 1088 * 100vw);
		margin-top: calc(8 / 1088 * 100vw);
	}
	@include max-screen($breakpoint-sp) {
		display: none;
	}
	li {
		&::after {
			content: '｜';
		}
		&:last-child::after {
			content: '';
		}
	}
	a {
		color: $main-font-color;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}


/* .header__btn-menu */
.header__btn-menu {
	display: none;
	@include max-screen($breakpoint-sp) {
		background: #0086CA url("../img/common/header_btn_menu_ico_01.svg") 50% 50% no-repeat;
		background-size: 100% 100%;
		border: 1px solid rgba($color: #000000, $alpha: .1);
		cursor: pointer;
		display: block;
		height: calc(56 / $sp-source-w * 100vw);
		position: absolute;
		right: 0;
		top: 0;
		width: calc(56 / $sp-source-w * 100vw);
		&.opened {
			background-image: url("../img/common/header_btn_menu_ico_02.svg");
		}
	}
}


/* .header__btn-search */
.header__btn-search {
	display: none;
	@include max-screen($breakpoint-sp) {
		background: #0086CA url("../img/common/header_btn_search_ico_01.svg") 50% 50% no-repeat;
		background-size: 100% 100%;
		border: 1px solid rgba($color: #000000, $alpha: .1);
		cursor: pointer;
		display: block;
		height: calc(56 / $sp-source-w * 100vw);
		position: absolute;
		right: calc(56 / $sp-source-w * 100vw);
		top: 0;
		width: calc(56 / $sp-source-w * 100vw);
		&.opened {
			background-image: url("../img/common/header_btn_menu_ico_02.svg");
		}
	}
}


/* Gnav
--------------------- */

.gnav {
	background: #FFF;
	border-bottom: 1px solid #E5E5E5;
	height: 61px;
	left: 0;
	position: fixed;
	top: 87px;
	width: 100%;
	z-index: 8;
	@media only screen and (max-width: 1088px) {
		height: calc(61 / 1088 * 100vw);
		top: calc(87 / 1088 * 100vw);
	}
	@include max-screen($breakpoint-sp) {
		display: none;
		height: 100%;
		left: 0;
		overflow: auto;
		padding-top: calc(56 / $sp-source-w * 100vw);
		position: fixed;
		top: 0;
	}
	a {
		color: $main-font-color;
	}
	> ul {
		display: flex;
		height: 100%;
		justify-content: space-between;
		line-height: 1;
		margin: 0 auto;
		max-width: 1088px;
		@include max-screen($breakpoint-sp) {
			border-top: 1px solid #E5E5E5;
			display: block;
			height: auto;
		}
		> li {
			> span,
			> a {
				color: $main-font-color;
				cursor: pointer;
				display: table-cell;
				font-size: 1.6rem;
				font-family: $mincho;
				font-weight: 500;
				height: 100%;
				padding-left: 16px;
				position: relative;
				text-decoration: none;
				vertical-align: middle;
				@media only screen and (max-width: 1088px) {
					font-size: calc(16 / 1088 * 100vw);
				}
				@include max-screen($breakpoint-sp) {
					font-family: $gothic;
					font-size: calc(14 / $sp-source-w * 100vw);
					padding-left: calc(24 / $sp-source-w * 100vw);
				}
				&::after {
					content: '\f107';
					font-family: "Font Awesome 5 Pro";
					font-size: 1.6rem;
					font-weight: 900;
					height: 16px;
					line-height: 16px;
					margin-top: -8px;
					position: absolute;
					right: 16px;
					top: 50%;
				}
				&:hover {
					color: #FFF;
					@include max-screen($breakpoint-sp) {
						color: $main-font-color;
					}
				}
				&::before {
					background: #0086CA;
					content: '';
					height: 0;
					left: 0;
					position: absolute;
					top: 0;
					transition: 0.2s ease-in-out;
					width: 100%;
					z-index: -1;
					@include max-screen($breakpoint-sp) {
						background: #E5E5E5;
						height: calc(32 / $sp-source-w * 100vw);
						margin-top: calc(-16 / $sp-source-w * 100vw);
						top: 50%;
						transition: none;
						width: calc(8 / $sp-source-w * 100vw);
						z-index: 1;
					}
				}
				&:hover::before {
					height: 100%;
					@include max-screen($breakpoint-sp) {
						height: calc(32 / $sp-source-w * 100vw);
					}
				}
			}
			> span {
				@include max-screen($breakpoint-sp) {
					display: block;
					height: calc(56 / $sp-source-w * 100vw);
					line-height: calc(56 / $sp-source-w * 100vw);
				}
				&::after {
					@include max-screen($breakpoint-sp) {
						background: url("../img/common/gnav_ico_02.svg") 50% 50% no-repeat;
						background-size: 100% auto;
						border-left: 1px solid #E5E5E5;
						content: '';
						display: block;
						height: 100%;
						margin-top: 0;
						right: 0;
						top: 0;
						width: calc(56 / $sp-source-w * 100vw);
					}
				}
			}
			> a {
				&::after {
					@include max-screen($breakpoint-sp) {
						background: url("../img/common/gnav_ico_01.svg") 50% 50%;
						background-size: calc(16 / $sp-source-w * 100vw) auto;
						content: '';
						display: inline-block;
						height: calc(16 / $sp-source-w * 100vw);
						margin-left: calc(8 / $sp-source-w * 100vw);
						margin-top: 0;
						position: relative;
						right: 0;
						top: 0;
						vertical-align: middle;
						width: calc(16 / $sp-source-w * 100vw);
					}
				}
			}
		}
		> li {
			border-right: 1px solid #E5E5E5;
			display: table;
			height: 100%;
			position: relative;
			width: calc(282 / 1088 * 100%);
			@include max-screen($breakpoint-sp) {
				border-bottom: 1px solid #E5E5E5;
				border-right: 0;
				height: calc(56 / $sp-source-w * 100vw);
				width: 100%;
				&.sp-only {
					display: table!important;
				}
			}
			&:first-child {
				border-left: 1px solid #E5E5E5;
				text-align: center;
				width: calc(242 / 1088 * 100%);
				@include max-screen($breakpoint-sp) {
					border-left: 0;
					text-align: left;
					width: 100%;
					.sp-only {
						display: inline!important;
					}
				}
				> span,
				> a {
					font-weight: 500;
					padding-left: 0;
					@include max-screen($breakpoint-sp) {
						font-family: $gothic;
						font-size: calc(14 / $sp-source-w * 100vw);
						padding-left: calc(24 / $sp-source-w * 100vw);
					}
					&::after {
						content: '';
					}
				}
				.icon-home {
					font-family: "Font Awesome 5 Pro";
					font-size: 2.4rem;
					font-weight: 900;
				}
			}
			&.current::before {
				background: #0086CA;
				content: '';
				height: 4px;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
				@include max-screen($breakpoint-sp) {
					display: none;
				}
			}
		}
	}
	.header__contact {
		display: none;
		@include max-screen($breakpoint-sp) {
			border-top: 1px solid #E5E5E5;
			display: block;
			margin-left: 0;
			padding: calc(16 / $sp-source-w * 100vw) 0;
			&__hd {
				font-size: calc(23 / $sp-source-w * 100vw);
				height: calc(32 / $sp-source-w * 100vw);
				line-height: calc(32 / $sp-source-w * 100vw);
				margin: 0 auto 1vw;
				width: calc(296 / $sp-source-w * 100vw);
			}
			&__tel {
				font-size: calc(43 / $sp-source-w * 100vw);
				margin-bottom: 2vw;
				text-align: center;
				a {
					background: url("../img/common/header_contact_ico_02.svg") 0% 50% no-repeat;
					background-size: calc(28 / $sp-source-w * 100vw) calc(28 / $sp-source-w * 100vw);
					color: #FFF;
					display: inline-block;
					padding-left: calc(40 / $sp-source-w * 100vw);
				}
			}
			&__time {
				color: #FFF;
				font-size: calc(18 / $sp-source-w * 100vw);
			}
		}
	}
	.gnav__btn-return {
		display: none;
		@include max-screen($breakpoint-sp) {
			background: #0086CA url("../img/common/header_btn_menu_ico_03.svg") 50% 50% no-repeat;
			background-size: 100% 100%;
			border: 1px solid #0086CA;
			cursor: pointer;
			height: calc(56 / $sp-source-w * 100vw);
			position: fixed;
			right: 0;
			top: 0;
			width: calc(56 / $sp-source-w * 100vw);
			z-index: 3;
		}
	}
}
.gnav__lnav {
	background-color: rgba($color: #fff, $alpha: 0.95);
	left: 50%;
	margin-left: -544px;
	padding: 24px 12px;
	position: fixed;
	top: 147px;
	width: 1088px;
	z-index: 2;
	@media only screen and (max-width: 1088px) {
		left: 0;
		margin-left: 0;
		top: calc(147 / 1088 * 100vw);
		width: 100%;
	}
	@include max-screen($breakpoint-sp) {
		background: #fff;
		left: 0;
		margin-left: 0;
		height: 100%;
		padding: 0;
		top: 0;
		width: 100%;
	}
	&__parent-hd {
		display: none;
		@include max-screen($breakpoint-sp) {
			box-shadow: 0 1px 0 0 #E5E5E5;
			display: block;
			font-family: $mincho;
			font-weight: 700;
			font-size: calc(20 / $sp-source-w * 100vw);
			height: calc(56 / $sp-source-w * 100vw);
			line-height: calc(56 / $sp-source-w * 100vw);
			padding: 0 0 0 calc(16 / $sp-source-w * 100vw);
		}
	}
	&__hd {
		border-left: 8px solid #E5E5E5;
		font-size: 1.4rem;
		font-weight: 500;
		line-height: 1;
		margin-left: 12px;
		padding: 9px 0 9px 16px;
		@include max-screen($breakpoint-sp) {
			border-top: 1px solid #E5E5E5;
			border-left: 0;
			font-size: calc(14 / $sp-source-w * 100vw);
			height: calc(56 / $sp-source-w * 100vw);
			line-height: calc(56 / $sp-source-w * 100vw);
			margin-left: 0;
			padding: 0;
			position: relative;
			&.has-child {
				.ico {
					@include max-screen($breakpoint-sp) {
						background: url("../img/common/gnav_ico_04.svg") 50% 50% no-repeat;
						background-size: 100% auto;
						border-left: 1px solid #E5E5E5;
						display: block;
						height: 100%;
						position: absolute;
						right: 0;
						top: 0;
						width: calc(56 / $sp-source-w * 100vw);
					}
				}
			}
		}
		@include max-screen($breakpoint-sp) {
			&.opened {
				.ico {
					background-image: url("../img/common/gnav_ico_03.svg");
				}
			}
		}
		& + .gnav__lnav__cols {
			@include max-screen($breakpoint-sp) {
				position: relative;
				&::after {
					background: #E5E5E5;
					bottom: -1px;
					content: '';
					height: 1px;
					left: 0;
					position: absolute;
					width: 100%;
				}
			}
		}
		&--last {
			@extend .gnav__lnav__hd;
			@include max-screen($breakpoint-sp) {
				border-bottom: 1px solid #E5E5E5;
			}
			& + .gnav__lnav__cols {
				border-top: 0!important;
			}
		}
		a {
			position: relative;
			text-decoration: none;
			@include max-screen($breakpoint-sp) {
				display: block;
				padding-left: calc(24 / $sp-source-w * 100vw);
			}
			&::after {
				background: url("../img/common/gnav_ico_01.svg") 50% 50%;
				background-size: 100% 100%;
				content: '';
				display: inline-block;
				height: 16px;
				margin-left: 8px;
				vertical-align: middle;
				width: 16px;
				@include max-screen($breakpoint-sp) {
					background-size: calc(16 / $sp-source-w * 100vw) calc(16 / $sp-source-w * 100vw);
					height: calc(16 / $sp-source-w * 100vw);
					margin-left: calc(8 / $sp-source-w * 100vw);
					width: calc(16 / $sp-source-w * 100vw);
				}
			}
			&::before {
				@include max-screen($breakpoint-sp) {
					background: #E5E5E5;
					content: '';
					height: calc(32 / $sp-source-w * 100vw);
					left: 0;
					margin-top: calc(-16 / $sp-source-w * 100vw);
					position: absolute;
					top: 50%;
					transition: none;
					width: calc(8 / $sp-source-w * 100vw);
					z-index: 1;
				}
			}
			&:hover {
				opacity: 0.5;
				@include max-screen($breakpoint-sp) {
					opacity: 1;
				}
			}
		}
	}
	ul {
		display: flex;
		flex-wrap: wrap;
		margin-left: 12px;
		margin-right: 12px;
		margin-top: 12px;
		@include max-screen($breakpoint-sp) {
			justify-content: space-between;
			margin-left: 0;
			margin-right: 0;
			margin-top: 0;
		}
	}
	li {
		border-bottom: 1px solid #E5E5E5;
		border-top: 1px solid #E5E5E5;
		padding: 12.5px 0;
		margin-right: calc(24 / 1040 * 100%);
		width: calc(330 / 1040 * 100%);
		&:nth-child(3n) {
			margin-right: 0;
		}
		@include max-screen($breakpoint-sp) {
			border-top: 0;
			display: table;
			margin-right: 0;
			padding: 0;
			width: 50%;
			&:nth-child(2n+1) {
				border-right: 1px solid #E5E5E5;
			}
		}
		a {
			display: inline-block;
			font-size: 1.4rem;
			line-height: calc(15.75 / 14);
			padding-left: 14px;
			position: relative;
			text-decoration: none;
			@include max-screen($breakpoint-sp) {
				display: table-cell;
				font-size: calc(14 / $sp-source-w * 100vw);
				height: calc(56 / $sp-source-w * 100vw);
				padding-left: calc(24 / $sp-source-w * 100vw);
				vertical-align: middle;
			}
			&::after {
				content: '\f0da';
				font-family: "Font Awesome 5 Pro";
				font-weight: 900;
				left: 0;
				line-height: 1;
				margin-top: -7px;
				position: absolute;
				top: 50%;
				@include max-screen($breakpoint-sp) {
					left: calc(10 / $sp-source-w * 100vw);
				}
			}
			&:hover {
				opacity: 0.5;
				@include max-screen($breakpoint-sp) {
					opacity: 1;
				}
			}
			span {
				font-size: 1.1rem;
				@include max-screen($breakpoint-sp) {
					font-size: calc(11 / $sp-source-w * 100vw);
				}
			}
		}
	}
	&__cols {
		display: flex;
		justify-content: space-between;
		@include max-screen($breakpoint-sp) {
			.gnav__lnav__cols {
				border-top: 1px solid #E5E5E5;
				flex-wrap: wrap;
			}
		}
	}
	> .gnav__lnav__cols {
		@include max-screen($breakpoint-sp) {
			height: calc(100% - 14.9333vw);
			overflow: auto;
		}
	}
	.gnav__lnav__col {
		width: 100%;
		@include max-screen($breakpoint-sp) {
			display: block;
			position: relative;
			width: 100%!important;
		}
		.gnav__lnav__col {
			padding: 12px 12px 0 12px;
			@include max-screen($breakpoint-sp) {
				padding: 0;
				width: 50%!important;
			}
		}
	}
}


/* Contents
--------------------- */

.contents {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	margin: 0 auto;
	max-width: 1104px;
	padding: 0 8px;
	width: 100%;
	@include max-screen($breakpoint-sp) {
		padding: 6.4vw;
	}
	&--single {
		display: block;
		margin: 32px auto 0;
		max-width: 1104px;
		padding: 0 8px;
		width: 100%;
		@include max-screen($breakpoint-sp) {
			margin-top: 0;
			padding: 6.4vw;
		}
	}
}


/* Main
--------------------- */

.main {
	padding: 40px 0;
	width: calc(806% / 1088 * 100);
	@include max-screen($breakpoint-sp) {
		padding: 0;
		width: 100%;
	}
}


/* Sidebar
--------------------- */

.sidebar {
	padding: 40px 0;
	width: calc(242% / 1088 * 100);
	@include max-screen($breakpoint-sp) {
		display: none;
	}
	&__nav {
		padding-bottom: 32px;
		a {
			color: $main-font-color;
			display: block;
			font-size: 1.4rem;
			line-height: 1;
			padding: 11px 0 11px 14px;
			position: relative;
			text-decoration: none;
			@media screen and (max-width: 1088px) {
				font-size: calc(14vw / 1088 * 100);
				padding: calc(11vw / 1088 * 100) 0 calc(11vw / 1088 * 100) calc(14vw / 1088 * 100);
			}
			&::after {
				content: ' \f0da';
				font-family: $awesome;
				font-weight: 900;
				left: 0;
				margin-top: -0.5em;
				position: absolute;
				top: 50%;
			}
			&.current,
			&:hover {
				font-weight: 700;
			}
			span {
				font-size: 1.1rem;
			}
		}
		li {
			li {
				padding-left: 14px;
				@media screen and (max-width: 1088px) {
					padding-left: calc(14vw / 1088 * 100);
				}
				a {
					&::after {
						content: ' \f105';
						font-weight: normal;
					}
				}
			}
		}
	}
}


/* Page-top
--------------------- */

$page-top-size-pc: 64px;
$page-top-size-sp: calc(56vw / $sp-source-w) * 100;
$page-top-margin-pc: 0;
$page-top-margin-sp: 0;
.page-top {
	height: $page-top-size-pc;
	overflow: hidden;
	padding-right: $page-top-margin-pc;
	position: absolute;
	right: 0;
	text-align: center;
	top: $page-top-size-pc * -1;
	width: $page-top-size-pc;
	z-index: 9;
	@media screen and (max-width: 767px) {
		height: $page-top-size-sp;
		padding-right: $page-top-margin-sp;
		top: $page-top-size-sp * -1;
		width: $page-top-size-sp;
	}
	&.fixed {
		bottom: $page-top-margin-pc;
		position: fixed;
		top: auto;
		@media screen and (max-width: 767px) {
			bottom: 0;
		}
	}
	&:hover {
		opacity: 0.5;
	}
	> div {
		background: #A8AAB7;
		cursor: pointer;
		height: 100%;
		line-height: 1;
		position: relative;
		right: 0;
		top: 0;
		width: 100%;
		img {
			height: 100%;
			width: 100%;
		}
	}
}


/* Footer
--------------------- */

.footer {
	background-color: #fff;
	position: relative;
	width: 100%;
	z-index: 7;
}

/* Row1 */
.footer__row1 {
	background-color: #fff;
	padding: 60px 8px 42px;
	@media screen and (max-width: 1088px) {
		padding: calc(60 / 1088 * 100vw) calc(8 / 1088 * 100vw) calc(42 / 1088 * 100vw);
	}
	@include max-screen($breakpoint-sp) {
		display: none;
	}
	> div {
		display: flex;
		margin: 0 auto;
		max-width: 1280px;
		width: 100%;
	}
	&__col {
		margin-right: calc(40 / 1280 * 100%);
		width: calc(224 / 1280 * 100%);
		&:last-child {
			margin-right: 0;
		}
		> ul {
			> li {
				padding-bottom: 16px;
				@media screen and (max-width: 1088px) {
					padding-bottom: calc(16 / 1088 * 100vw);
				}
				> a {
					box-shadow: 0 1px 0 #ccc, 0 2px 0 #fff;
					color: $main-font-color;
					display: block;
					font-size: 1.8rem;
					line-height: calc(23.8 / 18);
					padding-bottom: 16px;
					padding-left: 24px;
					position: relative;
					text-decoration: none;
					@media screen and (max-width: 1088px) {
						font-size: calc(18 / 1088 * 100vw);
						padding-bottom: calc(16 / 1088 * 100vw);
						padding-left: calc(24 / 1088 * 100vw);
					}
					&::after {
						align-items: center;
						background-color: $main-font-color;
						border-radius: 8px;
						color: #fff;
						content: "\f0da";
						display: flex;
						font-size: 1.4rem;
						font-family: $awesome;
						font-weight: 900;
						height: 16px;
						justify-content: center;
						left: 0;
						line-height: 1;
						position: absolute;
						top: 0.3em;
						width: 16px;
						@media screen and (max-width: 1088px) {
							border-radius: calc(8 / 1088 * 100vw);
							font-size: calc(14 / 1088 * 100vw);
							height: calc(16 / 1088 * 100vw);
							width: calc(16 / 1088 * 100vw);
						}
						@include max-screen($breakpoint-sp) {
							font-size: calc(11 / $sp-source-w * 100vw);
						}
					}
				}
			}
			ul {
				margin-top: 16px;
				@media screen and (max-width: 1088px) {
					margin-top: calc(16 / 1088 * 100vw);
				}
				li {
					font-size: 1.4rem;
					line-height: 1.2;
					margin-bottom: 16px;
					@media screen and (max-width: 1088px) {
						font-size: calc(14 / 1088 * 100vw);
						margin-bottom: calc(16 / 1088 * 100vw);
					}
					a {
						color: $main-font-color;
						display: inline-block;
						padding-left: 24px;
						position: relative;
						text-decoration: none;
						@media screen and (max-width: 1088px) {
							padding-left: calc(24 / 1088 * 100vw);
						}
						&::after {
							content: "\f0da";
							font-size: 1.4rem;
							font-family: $awesome;
							font-weight: 900;
							left: 7px;
							line-height: 1;
							position: absolute;
							top: 0.1em;
							@media screen and (max-width: 1088px) {
								font-size: calc(14 / 1088 * 100vw);
								left: calc(7 / 1088 * 100vw);
							}
							@include max-screen($breakpoint-sp) {
								font-size: calc(11 / $sp-source-w * 100vw);
							}
						}
					}
				}
			}
		}
	}
}

/* Row2 */
.footer__row2 {
	background-color: #fff;
	padding: 40px 8px 32px;
	@media screen and (max-width: 1088px) {
		padding: calc(60 / 1088 * 100vw) calc(8 / 1088 * 100vw);
	}
	@include max-screen($breakpoint-sp) {
		padding: calc(32 / $sp-source-w * 100vw) calc(25 / $sp-source-w * 100vw) 0;
	}
	> div {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		max-width: 1280px;
		width: 100%;
		@include max-screen($breakpoint-sp) {
			align-items: flex-start;
			flex-direction: column;
		}
	}
	&__col {
		ul {
			display: flex;
			flex-wrap: wrap;
			@media screen and (max-width: 1088px) {
				flex-direction: column;
			}
			@include max-screen($breakpoint-sp) {
				flex-direction: row;
			}
		}
		li {
			margin-right: 40px;
			@include max-screen($breakpoint-sp) {
				margin-bottom: calc(40em / 12);
				margin-right: 0;
				width: 50%;
			}
		}
		a {
			color: $main-font-color;
			font-size: 1.2rem;
			line-height: 1;
			padding-left: 12px;
			position: relative;
			text-decoration: none;
			white-space: nowrap;
			@include max-screen($breakpoint-sp) {
				font-size: calc(11 / $sp-source-w * 100vw);
				padding-left: calc(12 / $sp-source-w * 100vw);
			}
			&::after {
				content: "\f0da";
				font-size: 1.2rem;
				font-family: $awesome;
				font-weight: 900;
				left: 0;
				line-height: 1;
				position: absolute;
				top: 0.35em;
				@media screen and (max-width: 1088px) {
					font-size: calc(12 / 1088 * 100vw);
				}
				@include max-screen($breakpoint-sp) {
					font-size: calc(12 / $sp-source-w * 100vw);
				}
			}
		}
	}
}

.footer__logo {
	@include max-screen($breakpoint-sp) {
		margin-bottom: calc(24 / $sp-source-w * 100vw);
	}
	img {
		width: 230px;
		@include max-screen($breakpoint-sp) {
			width: calc(180 / $sp-source-w * 100vw);
		}
	}
}

.footer__address {
	font-size: 1.1rem;
	line-height: calc(24 / 11);
	padding: 0 24px;
	@include max-screen($breakpoint-sp) {
		font-size: calc(12 / $sp-source-w * 100vw);
		line-height: calc(20.4 / 12);
		margin-bottom: calc(38 / $sp-source-w * 100vw);
		padding: 0;
	}
	dl {
		display: inline-block;
		position: relative;
		@include max-screen($breakpoint-sp) {
			display: block;
		}
		&::after {
			content: ' / ';
			@include max-screen($breakpoint-sp) {
				content: '';
			}
		}
		dt,
		dd {
			display: inline;
		}
		&:last-child {
			&::after {
				content: '';
			}
		}
	}
}

/* .footer__copyright */
.footer__copyright {
	background-color: #fff;
	font-size: 1.1rem;
	line-height: 1;
	padding: 0 8px 50px;
	@media screen and (max-width: 1088px) {
		padding: 0 calc(8 / 1088 * 100vw) calc(50 / 1088 * 100vw);
	}
	@include max-screen($breakpoint-sp) {
		font-size: calc(12vw / $sp-source-w * 100);
		padding: 0 calc(25 / $sp-source-w * 100vw) calc(40 / $sp-source-w * 100vw);
	}
	> div {
		margin: 0 auto;
		max-width: 1280px;
		text-align: right;
		width: 100%;
		@include max-screen($breakpoint-sp) {
			text-align: center;
		}
	}
}
